<i18n>
{
  "ru": {
    "downloadTemplateButton": "Скачать шаблон xls",
    "importEmployeesButton": "Загрузить xls",
    "changeTeamButton": "Редактировать команду",
    "deleteTeamButton": "Удалить команду",
    "confirmDismissEmployee": {
      "title": "Уволить сотрудника?",
      "text": "Сотрудник будет исключен из всех команд компании",
      "button": "Да, уволить"
    },
    "confirmInviteEmployee": {
      "title": "Отправить приглашение?",
      "text": "Данному пользователю будет направлено приглашение присоединиться к вашей компании",
      "button": "Да, отправить"
    },
    "confirmReinstateEmployee": {
      "title": "Восстановить сотрудника?",
      "text": "Сотрудник будет перемещён в список действующих сотрудников компании",
      "button": "Да, восстановить"
    },
    "confirmCancelInviteEmployee": {
      "title": "Отменить приглашение?",
      "text": "Если вы отмените приглашение, оно исчезнет у приглашенного пользователя",
      "button": "Да, отменить"
    },
    "confirmEnableAuthEmails": {
      "title": "Вы уверены?",
      "text": "Будет включена отправка пользователям писем с данными для авторизации",
      "button": "Да, включить"
    },
    "infoDeleteTeam": {
      "title": "Удаление команды",
      "successText": "В команде есть оценки 360. Для удаления команды и переноса сотрудников в новую команду обратитесь в поддержку.",
      "errorText": "Не удалось проверить наличие оценок 360. Для удаления команды и переноса сотрудников в новую команду обратитесь в поддержку."
    },
    "inviteEmployeeButton": "Пригласить сотрудника",
    "createTeamButton": "Создать команду",
    "authEmailsStateLabel": "Отправка писем для авторизации",
    "tooltips": {
      "downloadTemplate": "Скачайте шаблон для загрузки списка сотрудников в компанию",
      "importEmployees": "Загрузите заполненный шаблон для добавления сотрудников в компанию",
      "authEmailsState": "Если выбрана опция “Отключено”, то при добавлении сотрудников в компанию, пользователям не будут отправлены письма с логином и паролем для входа на платформу.<br/><br/>Если выбрана опция “Включено”, то при включении или в момент добавления сотрудников в компанию, им будут направлены письма с логином и паролей для входа на платформу."
    },
    "successMessages": {
      "dismissEmployee": "Сотрудник успешно уволен",
      "confirmReinstateEmployee": "Сотрудник успешно восстановлен",
      "importEmployees": "Добавляемым сотрудникам будет отправлено письмо на указанные email с приглашением в компанию и временным паролем",
      "confirmInviteEmployee": "Сотруднику отправлено приглашение",
      "cancelInvitationEmployee": "Приглашение успешно отменено",
      "deleteTeam": "Команда успешно удалена",
      "disableAuthEmails": "Отправка писем для авторизации отключена",
      "enableAuthEmails": "Отправка писем для авторизации включена"
    },
    "errorMessages": {
      "dismissEmployee": "Не удалось уволить сотрудника",
      "confirmReinstateEmployee": "Не удалось восстановить сотрудника",
      "importEmployees": "Не удалось добавить сотрудников",
      "confirmInviteEmployee": "Не удалось пригласить сотрудника",
      "cancelInvitationEmployee": "Не удалось отменить приглашение",
      "deleteTeam": "Не удалось удалить команду",
      "disableAuthEmails": "Не удалось отключить отправку писем для авторизации",
      "enableAuthEmails": "Не удалось включить отправку писем для авторизации"
    }
  },
  "en": {
    "downloadTemplateButton": "Download the template xls",
    "importEmployeesButton": "Upload xls",
    "changeTeamButton": "Edit the team",
    "deleteTeamButton": "Delete the team",
    "confirmDismissEmployee": {
      "title": "Dismiss the employee?",
      "text": "The employee will be excluded from all company teams",
      "button": "Yes, dismiss"
    },
    "confirmInviteEmployee": {
      "title": "Send an invitation?",
      "text": "This user will be sent an invitation to join your company",
      "button": "Yes, send"
    },
    "confirmReinstateEmployee": {
      "title": "Reinstate the employee?",
      "text": "The employee will be moved to the list of current employees of the company.",
      "button": "Yes, reinstate"
    },
    "confirmCancelInviteEmployee": {
      "title": "Cancel an invitation?",
      "text": "If you cancel the invitation, it will disappear from the invited user",
      "button": "Yes, cancel"
    },
    "confirmEnableAuthEmails": {
      "title": "Are you sure?",
      "text": "Sending emails with authorization data to users will be enabled",
      "button": "Yes, enable"
    },
    "infoDeleteTeam": {
      "title": "Deleting the team",
      "successText": "The team has 360 scores. To delete the team and move employees to a new team, contact support.",
      "errorText": "Failed to check for 360 scores in the team. To delete the team and move employees to a new team, contact support."
    },
    "inviteEmployeeButton": "Invite employee",
    "createTeamButton": "Create team",
    "authEmailsStateLabel": "Sending emails for authorization",
    "tooltips": {
      "downloadTemplate": "Download the template to upload a list of employees to the company",
      "importEmployees": "Upload the completed template to add employees to the company",
      "authEmailsState": "If the “Disabled” option is selected, then when adding employees to the company, users will not be sent emails with a username and password to log in to the platform.<br/><br/>If the “Enabled” option is selected, then when employees are turned on or when they are added to the company, they will be sent emails with a username and passwords to log in to the platform."
    },
    "successMessages": {
      "dismissEmployee": "The employee was successfully dismissed",
      "confirmReinstateEmployee": "The employee was successfully reinstated",
      "importEmployees": "The added employees will be sent an email to the specified email address with an invitation to the company and a temporary password",
      "confirmInviteEmployee": "An invitation has been sent to the employee",
      "cancelInvitationEmployee": "Invitation successfully cancelled",
      "deleteTeam": "The team was successfully deleted",
      "disableAuthEmails": "Sending emails for authorization is disabled",
      "enableAuthEmails": "Sending emails for authorization is enabled"
    },
    "errorMessages": {
      "dismissEmployee": "Failed to dismiss the employee",
      "confirmReinstateEmployee": "Failed to reinstate the employee",
      "importEmployees": "Failed to import the employees",
      "confirmInviteEmployee": "Failed to invite an employee",
      "cancelInvitationEmployee": "Failed to cancel the invitation",
      "deleteTeam": "Failed to delete the team",
      "disableAuthEmails": "Failed to disable sending emails for authorization",
      "enableAuthEmails": "Failed to enable sending emails for authorization"
    }
  }
}
</i18n>
<template>
  <div class="company-employees-page">
    <div class="mb-10">
      <v-row align="center">
        <v-col cols="auto">
          <app-title size="middle" weight="bold">{{ $t('employees') }}</app-title>
        </v-col>
        <v-col cols="12" sm="auto" class="ms-auto">
          <div>
            <v-menu
              v-if="isAnyModuleEnabled && canManagePage"
              v-model="isMenuVisible.addEmployees"
              offset-y
            >
              <template #activator="{on, attrs}">
                <v-btn
                  block
                  depressed
                  color="primary"
                  v-bind="attrs"
                  :class="{'icon-rotate': isMenuVisible.addEmployees}"
                  class="primary-contrast--text mt-4 mt-sm-0"
                  v-on="on"
                >
                  <span>{{ $t('buttons.add') }}</span>
                  <v-icon right v-text="'mdi-chevron-down'"></v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="toggleVisibleDialog({name: 'inviteEmployee', state: true})">
                  <v-list-item-title>{{ $t('inviteEmployeeButton') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toggleVisibleDialog({name: 'createTeam', state: true})">
                  <v-list-item-title>{{ $t('createTeamButton') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isAnyModuleEnabled && canManagePage" align="end">
        <v-col cols="6" sm="auto" class="ms-auto">
          <v-tooltip top max-width="300" color="secondary">
            <template #activator="{on, attrs}">
              <v-btn
                :disabled="isLoading || isSubmitting"
                :outlined="$vuetify.breakpoint.mobile"
                :text="!$vuetify.breakpoint.mobile"
                block
                small
                class="mb-1"
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click="getTemplateFile"
                >{{ $t('downloadTemplateButton') }}</v-btn
              >
            </template>
            <span class="app-text-size--extra-small">{{ $t('tooltips.downloadTemplate') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6" sm="auto">
          <v-tooltip top max-width="300" color="secondary">
            <template #activator="{on, attrs}">
              <v-btn
                :disabled="isLoading || isSubmitting"
                :outlined="$vuetify.breakpoint.mobile"
                :text="!$vuetify.breakpoint.mobile"
                small
                block
                class="mb-1"
                type="file"
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click="$refs.fileInput.click()"
                >{{ $t('importEmployeesButton') }}</v-btn
              >
              <input
                ref="fileInput"
                type="file"
                name="document"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                class="d-none"
                @change="onFileSelected"
              />
            </template>
            <span class="app-text-size--extra-small">{{ $t('tooltips.importEmployees') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-switch
            v-model="authEmailsState"
            :disabled="isLoading || isSubmitting"
            class="mb-1"
            color="blue"
            inset
            dense
            hide-details
            @change="toggleAuthEmails"
          >
            <template #label>
              <v-tooltip top max-width="300" color="secondary">
                <template #activator="{on, attrs}">
                  <div
                    class="me-2 d-flex align-center app-text-size--normal shades--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ $t('authEmailsStateLabel') }}</span>
                    <v-icon small color="blue" class="ms-1 mb-2">mdi-information-outline</v-icon>
                  </div>
                </template>
                <!-- eslint-disable vue/no-v-html -->
                <div class="app-text-size--extra-small" v-html="$t('tooltips.authEmailsState')" />
                <!--eslint-enable-->
              </v-tooltip>
            </template>
          </v-switch>
        </v-col>
      </v-row>
      <app-expansion-filters-bar
        v-if="isAnyModuleEnabled"
        :count-active-filters="countActiveFilters"
        @reset-filters="resetFilters"
      >
        <template #action-button>
          <v-col v-if="countActiveFilters" cols="auto">
            <v-btn
              :small="$vuetify.breakpoint.mobile"
              text
              color="blue"
              block
              @click="onApplyFilters"
            >
              <span>{{ $t('buttons.applyFilter') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="ms-auto">
            <v-btn
              v-if="localEmployees && localEmployees.length && selectStatus === 'current'"
              text
              color="blue"
              small
              @click="toggleOpenAccordion(!isOpenAccordion)"
            >
              {{ isOpenAccordion ? $t('buttons.collapseAll') : $t('buttons.expandAll') }}
            </v-btn>
          </v-col>
        </template>
        <template #filters>
          <v-row align="end">
            <v-col v-if="selectStatus === 'current'" cols="12" sm="6" md="4" xl="3">
              <span class="grey--text">{{ $t('team') }}</span>
              <v-autocomplete
                v-model="teamIds"
                :search-input.sync="searchTeams"
                :items="localTeams"
                item-text="team_name"
                item-value="team_id"
                item-color="blue"
                append-icon="mdi-chevron-down"
                :menu-props="{closeOnContentClick: false}"
                outlined
                dense
                clearable
                multiple
                hide-details
                single-line
                class="pt-2"
                :label="$t('placeholders.all')"
                @change="searchTeams = null"
                @click:clear="clearTeams"
              >
                <template #selection="{item, index}">
                  <span v-if="index === 0" class="app-text-ellipsis" :style="{maxWidth: '50%'}">{{
                    item.team_name
                  }}</span>
                  <span v-if="index === 1" class="ms-2 grey--text app-text-size--extra-small">
                    (+{{ teamIds.length - 1 }})
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" xl="3">
              <span class="grey--text">{{ $t('userMetadata.fullName') }}</span>
              <v-autocomplete
                v-model="employeeId"
                :items="employeesUsers"
                item-text="full_name"
                item-value="employee_id"
                item-color="blue"
                append-icon="mdi-chevron-down"
                dense
                outlined
                clearable
                hide-details
                single-line
                :label="$t('placeholders.all')"
                class="pt-2"
                @click:clear="clearEmployee"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="selectStatus === 'current'" cols="12" sm="6" md="4" xl="3">
              <span class="grey--text">{{ $t('userMetadata.specialization') }}</span>
              <v-autocomplete
                v-model="specializationIds"
                :search-input.sync="searchSpecializationsInput"
                :items="companySpecializations"
                item-text="name"
                item-value="code"
                item-color="blue"
                append-icon="mdi-chevron-down"
                :menu-props="{closeOnContentClick: false}"
                outlined
                :disabled="Boolean(employeeId)"
                dense
                clearable
                hide-details
                multiple
                single-line
                class="pt-2"
                :label="$t('placeholders.all')"
                @change="searchSpecializationsInput = null"
                @click:clear="clearSpecs"
              >
                <template #selection="{item, index}">
                  <span v-if="index === 0" class="app-text-ellipsis" :style="{maxWidth: '70%'}">{{
                    item.name
                  }}</span>
                  <span v-if="index === 1" class="ms-2 app-text-size--extra-small">
                    (+{{ specializationIds.length - 1 }})
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mobile" cols="12" sm="6" md="4">
              <span class="grey--text">{{ $t('status') }}</span>
              <v-select
                v-model="selectStatus"
                :items="employeeStatuses"
                item-text="text"
                item-value="value"
                item-color="blue"
                append-icon="mdi-chevron-down"
                outlined
                dense
                hide-details
                :menu-props="{maxHeight: '400', offsetY: true}"
                class="pt-2"
              />
            </v-col>
            <v-col v-else cols="12">
              <v-radio-group v-model="selectStatus" dense row hide-details>
                <v-radio
                  v-for="status in employeeStatuses"
                  :key="status.value"
                  :value="status.value"
                  :label="status.text"
                  color="blue"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </template>
        <template #results>
          <v-divider class="mt-4 mb-2"></v-divider>
          <v-chip-group column>
            <template v-if="teamIds.length">
              <v-chip
                v-for="(teamId, index) in teamIds"
                :key="teamId"
                outlined
                @click="teamIds.splice(index, 1)"
              >
                <span>{{ getTeamName(teamId) }}</span>
                <v-icon class="ms-1" small color="blue" v-text="'mdi-close'"></v-icon>
              </v-chip>
            </template>
            <v-chip v-if="employeeId !== null" outlined @click="employeeId = null">
              <span>{{ getEmployeeName(employeeId) }}</span>
              <v-icon class="ms-1" small color="blue" v-text="'mdi-close'"></v-icon>
            </v-chip>
            <template v-if="specializationIds.length">
              <v-chip
                v-for="(specializationId, index) in specializationIds"
                :key="specializationId"
                outlined
                @click="specializationIds.splice(index, 1)"
              >
                <span>{{ getSpecializationName(specializationId) }}</span>
                <v-icon class="ms-1" small color="blue" v-text="'mdi-close'"></v-icon>
              </v-chip>
            </template>
          </v-chip-group>
        </template>
      </app-expansion-filters-bar>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="localEmployees">
        <template
          v-if="isAnyModuleEnabled && !employeeId && selectStatus === 'current' && !isLoading"
        >
          <div v-if="localEmployees.length">
            <v-expansion-panels v-model="accordion" accordion multiple>
              <v-expansion-panel
                v-for="team in localEmployees"
                :key="team.id"
                :readonly="!(team.employees || []).length"
              >
                <v-expansion-panel-header :hide-actions="!(team.employees || []).length">
                  <template v-if="!$vuetify.breakpoint.mobile">
                    <v-btn
                      :to="{name: $routeNames.main.team.teamMembers, params: {id: team.id}}"
                      text
                      :disabled="team.id === -1"
                      color="blue"
                      class="flex-grow-0 me-5 app-text-ellipsis company-employees-page__btn"
                    >
                      <app-title size="small">
                        {{ team.id === -1 ? $t('withoutTeam') : team.name }}
                      </app-title>
                    </v-btn>
                    <div class="flex-grow-0 d-flex align-center">
                      <v-icon small v-text="'mdi-account-multiple'"></v-icon>
                      <span class="app-text-size--small grey--text ms-2">
                        {{ (team.employees || []).length }}&nbsp;{{
                          declOfNum((team.employees || []).length, $t('quantity.people'))
                        }}
                      </span>
                    </div>
                    <template v-if="canManagePage && team.id !== -1">
                      <v-tooltip top max-width="300" color="secondary">
                        <template #activator="{on, attrs}">
                          <v-btn
                            fab
                            icon
                            small
                            color="blue"
                            class="flex-grow-0 ms-5"
                            v-bind="attrs"
                            v-on="on"
                            @click="openChangeTeamDialog(team)"
                          >
                            <v-icon v-text="'mdi-playlist-edit'"></v-icon>
                          </v-btn>
                        </template>
                        <span class="app-text-size--extra-small">{{ $t('changeTeamButton') }}</span>
                      </v-tooltip>
                      <v-tooltip top max-width="300" color="secondary">
                        <template #activator="{on, attrs}">
                          <v-btn
                            :disabled="checkTeamTests360Loading || isSubmitting"
                            fab
                            icon
                            small
                            color="warning"
                            class="flex-grow-0"
                            v-bind="attrs"
                            v-on="on"
                            @click="checkDeleteTeam(team)"
                          >
                            <v-icon v-text="'mdi-delete-outline'"></v-icon>
                          </v-btn>
                        </template>
                        <span class="app-text-size--extra-small">{{ $t('deleteTeamButton') }}</span>
                      </v-tooltip>
                    </template>
                  </template>
                  <div v-else class="d-flex flex-column align-start">
                    <app-title size="small">
                      {{ team.name }}
                    </app-title>
                    <v-btn
                      :to="{name: $routeNames.main.team.teamMembers, params: {id: team.id}}"
                      class="mt-3"
                      outlined
                      x-small
                      color="blue"
                    >
                      <span>{{ $t('buttons.link') }}</span>
                      <v-icon right v-text="'mdi-chevron-right'"></v-icon>
                    </v-btn>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-if="$vuetify.breakpoint.mobile" class="mb-5">
                    <div class="d-flex align-center">
                      <v-icon small v-text="'mdi-account-multiple'"></v-icon>
                      <span class="app-text-size--small grey--text ms-2">
                        {{ (team.employees || []).length }}&nbsp;{{
                          declOfNum((team.employees || []).length, $t('quantity.people'))
                        }}
                      </span>
                    </div>
                    <div v-if="canManagePage && team.id !== -1" class="mt-2 d-flex align-center">
                      <v-btn x-small outlined color="blue" @click="openChangeTeamDialog(team)">{{
                        $t('changeTeamButton')
                      }}</v-btn>
                      <v-btn
                        :disabled="checkTeamTests360Loading || isSubmitting"
                        x-small
                        outlined
                        color="warning"
                        class="ms-2"
                        @click="checkDeleteTeam(team)"
                        >{{ $t('deleteTeamButton') }}</v-btn
                      >
                    </div>
                  </div>
                  <v-list v-if="(team.employees || []).length" color="info">
                    <v-list-item
                      v-for="(employee, i) in team.employees"
                      :key="employee.id"
                      class="d-block"
                    >
                      <app-employee-card
                        :data="employee"
                        :options="{
                          isEditable: canManagePage,
                          canViewDashboards: canManagePage || canManageTeams,
                        }"
                        :specializations="
                          getEmployeeSpecs(team.id !== -1 ? team.id : false, employee)
                        "
                        @dismiss="openConfirmDialog('dismissEmployee', 'employee', employee)"
                        @reinstate="
                          openConfirmDialog('confirmReinstateEmployee', 'employee', employee)
                        "
                        @cancel-invite="
                          openConfirmDialog('cancelInvitationEmployee', 'employee', employee)
                        "
                      ></app-employee-card>
                      <v-divider
                        v-if="i + 1 < (team.employees || []).length"
                        class="my-3"
                      ></v-divider>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-pagination
              v-show="employeesCount > localEmployees.length"
              :value="pagination.page"
              color="blue darken-5"
              :total-visible="7"
              :length="Math.ceil(pagination.total / pagination.limit)"
              @input="onPaginate"
            ></v-pagination>
          </div>
          <template v-else>
            <p v-if="canManagePage" class="grey--text">
              {{ $t('stubs.noTeams') }}
            </p>
            <v-list v-if="localEmployees.length" color="info">
              <v-list-item
                v-for="(employee, i) in localEmployees"
                :key="employee.id"
                class="d-block"
              >
                <app-employee-card
                  :data="employee"
                  :options="{
                    isEditable: canManagePage,
                    canViewDashboards: canManagePage || canManageTeams,
                  }"
                  :specializations="getEmployeeSpecs(false, employee)"
                  @dismiss="openConfirmDialog('dismissEmployee', 'employee', employee)"
                  @reinstate="openConfirmDialog('confirmReinstateEmployee', 'employee', employee)"
                  @cancel-invite="
                    openConfirmDialog('cancelInvitationEmployee', 'employee', employee)
                  "
                ></app-employee-card>
                <v-divider v-if="i + 1 < localEmployees.length" class="my-3"></v-divider>
              </v-list-item>
            </v-list>
          </template>
        </template>
        <p
          v-else-if="isAnyModuleEnabled && !employeeId && selectStatus === 'current' && isLoading"
          class="grey--text"
        >
          {{ $t('loading') }}
        </p>
        <template v-else>
          <v-list v-if="localEmployees.length && !isLoading" color="info">
            <v-list-item
              v-for="(employee, i) in localEmployees[0].employees"
              :key="employee.id"
              class="d-block"
            >
              <app-employee-card
                :data="employee"
                :options="{
                  isEditable: isAnyModuleEnabled && canManagePage,
                  isShowTeams: isAnyModuleEnabled,
                  canViewDashboards: canManagePage || canManageTeams,
                }"
                :specializations="getEmployeeSpecs(false, employee)"
                @dismiss="openConfirmDialog('dismissEmployee', 'employee', employee)"
                @reinstate="openConfirmDialog('confirmReinstateEmployee', 'employee', employee)"
                @cancel-invite="openConfirmDialog('cancelInvitationEmployee', 'employee', employee)"
              ></app-employee-card>
              <v-divider v-if="i + 1 < localEmployees.length" class="my-3"></v-divider>
            </v-list-item>
          </v-list>
          <p v-else-if="localEmployees.length && isLoading" class="grey--text">
            {{ $t('loading') }}
          </p>
          <p v-else class="grey--text">{{ $t('stubs.noEmployees') }}</p>
        </template>
      </div>
    </transition>
    <app-confirm-dialog
      name="dismissEmployee"
      :is-visible="isDialogVisible.dismissEmployee"
      :title="$t('confirmDismissEmployee.title')"
      :button="$t('confirmDismissEmployee.button')"
      :is-submitting="isSubmitting"
      @submit="onDismissEmployee(selected.employee.employee_id)"
      @toggle-visible="toggleVisibleDialog"
    >
      <p>{{ $t('confirmDismissEmployee.text') }}</p>
      <p v-if="selected.employee" class="app-text-family--bold">
        {{ getFullName(selected.employee) }}
      </p>
    </app-confirm-dialog>
    <app-confirm-dialog
      name="confirmInviteEmployee"
      :is-visible="isDialogVisible.confirmInviteEmployee"
      :title="$t('confirmInviteEmployee.title')"
      :button="$t('confirmInviteEmployee.button')"
      :is-submitting="isSubmitting"
      @submit="onInviteEmployee(selected.employee.user_id)"
      @toggle-visible="toggleVisibleDialog"
    >
      <p>{{ $t('confirmInviteEmployee.text') }}</p>
      <p v-if="selected.employee" class="app-text-family--bold">
        {{ getFullName(selected.employee) }}
      </p>
    </app-confirm-dialog>
    <app-confirm-dialog
      name="confirmReinstateEmployee"
      :is-visible="isDialogVisible.confirmReinstateEmployee"
      :title="$t('confirmReinstateEmployee.title')"
      :button="$t('confirmReinstateEmployee.button')"
      :is-submitting="isSubmitting"
      @submit="onReinstateEmployee(selected.employee.employee_id)"
      @toggle-visible="toggleVisibleDialog"
    >
      <p>{{ $t('confirmReinstateEmployee.text') }}</p>
      <p v-if="selected.employee" class="app-text-family--bold">
        {{ getFullName(selected.employee) }}
      </p>
    </app-confirm-dialog>
    <app-confirm-dialog
      name="cancelInvitationEmployee"
      :is-visible="isDialogVisible.cancelInvitationEmployee"
      :title="$t('confirmCancelInviteEmployee.title')"
      :button="$t('confirmCancelInviteEmployee.button')"
      :is-submitting="isSubmitting"
      @submit="onCancelInviteEmployee(selected.employee.employee_id)"
      @toggle-visible="toggleVisibleDialog"
    >
      <p>{{ $t('confirmCancelInviteEmployee.text') }}</p>
      <p v-if="selected.employee" class="app-text-family--bold">
        {{ getFullName(selected.employee) }}
      </p>
    </app-confirm-dialog>
    <app-confirm-dialog
      name="deleteTeam"
      :is-visible="isDialogVisible.deleteTeam"
      :title="`${$t('deleteTeamButton')}?`"
      :is-submitting="isSubmitting"
      @submit="onDeleteTeam(selected.team.id)"
      @toggle-visible="toggleVisibleDialog"
    >
      <div v-if="selected.team">{{ selected.team.name }}</div>
    </app-confirm-dialog>
    <app-confirm-dialog
      name="enableAuthEmails"
      :is-visible="isDialogVisible.enableAuthEmails"
      :title="$t('confirmEnableAuthEmails.title')"
      :button="$t('confirmEnableAuthEmails.button')"
      :is-submitting="isSubmitting"
      @submit="onSwitchAuthEmails(true)"
      @close="authEmailsState = false"
      @toggle-visible="toggleVisibleDialog"
    >
      <p>{{ $t('confirmEnableAuthEmails.text') }}</p>
    </app-confirm-dialog>
    <app-create-team-dialog
      :is-visible="isDialogVisible.createTeam"
      @toggle-visible="toggleVisibleDialog"
    />
    <app-create-team-dialog
      :is-visible="isDialogVisible.changeTeam"
      is-editable
      :initial-values="selected.team"
      @toggle-visible="toggleVisibleDialog"
    />
    <app-invite-employee-dialog
      :is-visible="isDialogVisible.inviteEmployee"
      @toggle-visible="toggleVisibleDialog"
      @update-employees="onApplyFilters"
    >
    </app-invite-employee-dialog>
    <app-import-employees-dialog
      :is-visible="isDialogVisible.importEmployees"
      :pack-number="importEmployeesPackNumber"
      @success="resetFilters()"
      @toggle-visible="toggleVisibleDialog"
    />
    <app-info-dialog
      name="success"
      :is-visible="isDialogVisible.success"
      :title="successMessage.title"
      @toggle-visible="toggleVisibleDialog"
    >
      <p v-if="successMessage.text">{{ successMessage.text }}</p>
    </app-info-dialog>
    <app-info-dialog
      name="error"
      :is-visible="isDialogVisible.error"
      :title="errorMessage.title"
      @toggle-visible="toggleVisibleDialog"
    >
      <p v-if="errorMessage.text">{{ errorMessage.text }}</p>
      <article v-if="errorMessage.list" class="text-left app-text-size--small">
        <ul>
          <li v-for="(error, i) in errorMessage.list" :key="i">{{ error }}</li>
        </ul>
      </article>
    </app-info-dialog>
  </div>
</template>

<script>
import {dialogsMixin} from '@/mixins/dialogs';
import {accessRightsMixin} from '@/mixins/accessRights';

import {
  declOfNum,
  errorAlert,
  filterObject,
  getArrayByKey,
  getFile,
  getFullName,
  isEmptyObj,
  isObject,
  range,
} from '@/helpers/utils';
import {PAGINATION_LIMIT} from '@/components/pages/company/employees/CompanyEmployeesContainer';
// import _ from 'lodash';

const AppEmployeeCard = () => import('@/components/cards/EmployeeCardContainer');
const AppCreateTeamDialog = () =>
  import('@/components/pages/company/employees/dialogs/CreateTeamDialogContainer');
const AppInviteEmployeeDialog = () =>
  import('@/components/pages/company/employees/dialogs/InviteEmployeeDialogContainer');
const AppImportEmployeesDialog = () =>
  import('@/components/pages/company/employees/dialogs/ImportEmployeesDialogContainer');

export default {
  name: 'AppCompanyEmployees',
  components: {
    AppEmployeeCard,
    AppCreateTeamDialog,
    AppInviteEmployeeDialog,
    AppImportEmployeesDialog,
  },
  mixins: [dialogsMixin, accessRightsMixin],
  props: {
    isAnyModuleEnabled: {
      type: Boolean,
      default: false,
    },
    isAuthEmailsEnabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    checkTeamTests360Loading: {
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    validationErrors: {
      type: Array || String,
      default() {
        return [] || null;
      },
    },
    companySpecializations: {
      type: Array,
      default() {
        return [];
      },
    },
    companyTeams: {
      type: Array,
      default() {
        return [];
      },
    },
    employees: {
      type: Array,
      default() {
        return [];
      },
    },
    employeesUsers: {
      type: Array,
      default() {
        return [];
      },
    },
    employeesCount: {
      type: Number,
      default: 0,
    },
    employeeFilters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      declOfNum,
      range,
      getFullName,
      getArrayByKey,
      isDialogVisible: {
        enableAuthEmails: false,
        dismissEmployee: false,
        confirmReinstateEmployee: false,
        confirmInviteEmployee: false,
        cancelInvitationEmployee: false,
        inviteEmployee: false,
        importEmployees: false,
        deleteTeam: false,
        createTeam: false,
        changeTeam: false,
        success: false,
        error: false,
      },
      isMenuVisible: {
        addEmployees: false,
      },
      searchSpecializationsInput: null,
      selectedStatus: 'current',
      employeeStatuses: [
        {
          value: 'current',
          text: this.$t('employeeStatuses.current'),
        },
        {
          value: 'invited',
          text: this.$t('employeeStatuses.invited'),
        },
        {
          value: 'dismissed',
          text: this.$t('employeeStatuses.dismissed'),
        },
      ],
      importEmployeesPackNumber: 0,
      accordion: [],
      selected: {
        employee: null,
        team: null,
      },
      successMessage: {
        title: '',
        text: '',
      },
      errorMessage: {
        title: '',
        text: '',
        list: [],
      },
      searchTeams: null,
      authEmailsEnabled: false,
      offset: 0,
    };
  },
  computed: {
    pagination() {
      const {employeesCount, offset} = this;

      return {
        total: employeesCount,
        offset,
        limit: PAGINATION_LIMIT,
        page: offset / PAGINATION_LIMIT + 1,
      };
    },
    selectStatus: {
      get() {
        return this.selectedStatus;
      },
      set(value) {
        this.selectedStatus = value;
        this.onApplyFilters();
      },
    },
    teamIds: {
      get() {
        return this.employeeFilters.teamIds;
      },
      set(value) {
        // this.$emit('set-employee-filters', {key: 'employeeId', value: null});
        this.$emit('set-employee-filters', {key: 'teamIds', value});
      },
    },
    employeeId: {
      get() {
        return this.employeeFilters.employeeId;
      },
      set(value) {
        this.$emit('set-employee-filters', {
          key: 'employeeId',
          value,
          callback: this.onApplyFilters,
        });
      },
    },
    specializationIds: {
      get() {
        return this.employeeFilters.specializationIds;
      },
      set(value) {
        this.$emit('set-employee-filters', {key: 'specializationIds', value});
      },
    },
    companyId() {
      return this.$route.params.id || null;
    },
    isOpenAccordion() {
      return Boolean(this.accordion.length);
    },
    authEmailsState: {
      get() {
        return this.authEmailsEnabled;
      },
      set(value) {
        this.authEmailsEnabled = value;
      },
    },
    userNameFilter() {
      const {localEmployees, employeesUsers, teamIds} = this;
      if (!localEmployees || !localEmployees.length) return [];
      if (!employeesUsers || !employeesUsers.length) return [];
      if (!teamIds || !teamIds.length) return employeesUsers;
      return [...new Set(localEmployees.map((t) => t.employees).flat())].map((e) => e.employee_id);
    },
    localEmployees() {
      const {employees} = this;
      if (!employees || !employees.length) return [];

      const idx = employees.findIndex((e) => e.id === -1);
      if (idx >= 0) {
        employees[idx].name = this.$t('withoutTeam');
      }
      return employees;
    },
    localTeams() {
      const {companyTeams} = this;
      if (!companyTeams || !companyTeams.length) return [];
      const result = [...companyTeams];
      result.unshift({
        team_id: -1,
        team_name: this.$t('withoutTeam'),
      });
      return result;
    },
    countActiveFilters() {
      const checkFilter = (filter) => {
        return Array.isArray(filter)
          ? filter.length
          : isObject(filter)
          ? !isEmptyObj(filter)
          : !!filter;
      };

      return Object.keys(filterObject(this.employeeFilters, checkFilter)).length;
    },
  },
  watch: {
    employeeFilters() {
      this.offset = 0;
    },
  },
  created() {
    this.setAuthEmailState();
  },
  methods: {
    onPaginate(e) {
      this.accordion = [];
      this.offset = PAGINATION_LIMIT * (e - 1);
      this.onApplyFilters();
    },
    clearEmployee() {
      this.employeeId = null;
      this.onApplyFilters();
    },
    clearTeams() {
      this.teamIds = [];
      this.onApplyFilters();
    },
    clearSpecs() {
      this.specializationIds = [];
      this.onApplyFilters();
    },
    onApplyFilters() {
      const {selectedStatus, offset} = this;
      this.$emit('get-employees', {
        employee_state: selectedStatus,
        pagination: {offset, limit: PAGINATION_LIMIT},
      });
    },
    setAuthEmailState() {
      this.authEmailsEnabled = this.isAuthEmailsEnabled;
    },
    getEmployeeSpecs(teamId, {specializations, team_specs}) {
      if (!this.companySpecializations) return [];

      let codes = specializations;
      if (teamId) codes = Array.isArray(team_specs) ? team_specs : team_specs[teamId];

      return getArrayByKey(codes, this.companySpecializations, 'code', 'name');
    },
    getTeamName(value) {
      const team = this.localEmployees.find((t) => t.id === value);
      return team ? team.name : value;
    },
    getEmployeeName(value) {
      const employee = this.localEmployees.find((e) => e.employee_id === value);
      return employee ? employee.full_name : value;
    },
    getSpecializationName(value) {
      const spec = this.companySpecializations.find((s) => s.code === value);
      return spec ? spec.name : value;
    },
    toggleOpenAccordion(state) {
      const count =
        this.localEmployees.length < this.pagination.offset
          ? this.localEmployees.length
          : this.pagination.offset;

      state ? (this.accordion = range(0, count)) : (this.accordion = []);
    },
    toggleAuthEmails(switchMark) {
      if (switchMark) this.toggleVisibleDialog({name: 'enableAuthEmails', state: true});
      else this.onSwitchAuthEmails(switchMark);
    },
    openConfirmDialog(name, type, data) {
      this.selected[type] = data;
      this.toggleVisibleDialog({name, state: true});
    },
    openChangeTeamDialog(data) {
      this.selected.team = data;
      this.toggleVisibleDialog({name: 'changeTeam', state: true});
    },
    checkDeleteTeam(team) {
      this.selected.team = team;
      this.checkTeamTests360(this.selected.team.id);
    },
    checkTeamTests360(teamId) {
      this.$emit('check-team-tests-360', {
        teamId,
        successCallback: (response) => {
          if (response) {
            if (response.has_360) {
              this.successMessage.title = this.$t('infoDeleteTeam.title');
              this.successMessage.text = this.$t('infoDeleteTeam.successText');
              this.toggleVisibleDialog({name: 'success', state: true});
            } else {
              this.toggleVisibleDialog({name: 'deleteTeam', state: true});
            }
          }
        },
        errorCallback: () => {
          this.errorMessage.title = this.$t('infoDeleteTeam.title');
          this.errorMessage.text = this.$t('infoDeleteTeam.errorText');
          this.toggleVisibleDialog({name: 'error', state: true});
        },
      });
    },
    onFileSelected({target}) {
      const reader = new FileReader();
      reader.readAsDataURL(target.files[0]);
      reader.onload = () => this.onImportEmployees(reader.result);
    },
    resetFileInput() {
      this.$refs.fileInput.value = '';
    },
    getTemplateFile() {
      getFile('/example-employees');
    },
    onImportEmployees(data) {
      if (!data) return false;

      this.$emit('on-import-employees', {
        data,
        successCallback: (packNumber) => {
          this.importEmployeesPackNumber = packNumber;
          this.toggleVisibleDialog({name: 'importEmployees', state: true});
          this.resetFileInput();
        },
        errorCallback: () => {
          this.errorMessage.title = this.$t('errorMessages.importEmployees');
          this.errorMessage[Array.isArray(this.validationErrors) ? 'list' : 'text'] =
            this.validationErrors;
          this.toggleVisibleDialog({name: 'error', state: true});
          this.resetFileInput();
        },
      });
    },
    onInviteEmployee(userId) {
      this.$emit('on-invite-employee', {
        userId,
        successCallback: () => {
          this.successCallback('confirmInviteEmployee');
          this.resetFilters();
        },
        errorCallback: () => this.errorCallback(this.$t('errorMessages.confirmInviteEmployee')),
      });
    },
    onCancelInviteEmployee(employeeId) {
      this.$emit('on-cancel-invite-employee', {
        employeeId,
        successCallback: () => {
          this.successCallback('cancelInvitationEmployee');
          this.resetFilters();
        },
        errorCallback: () => this.errorCallback(this.$t('errorMessages.cancelInvitationEmployee')),
      });
    },
    onDismissEmployee(employeeId) {
      this.$emit('on-dismiss-employee', {
        employeeId,
        successCallback: () => {
          this.successCallback('dismissEmployee');
          this.resetFilters();
        },
        errorCallback: () => this.errorCallback(this.$t('errorMessages.dismissEmployee')),
      });
    },
    onReinstateEmployee(employeeId) {
      this.$emit('on-reinstate-employee', {
        employeeId,
        successCallback: () => {
          this.successCallback('confirmReinstateEmployee');
          this.resetFilters();
        },
        errorCallback: () => this.errorCallback(this.$t('errorMessages.confirmReinstateEmployee')),
      });
    },
    onDeleteTeam(teamId) {
      this.$emit('on-delete-team', {
        teamId,
        successCallback: () => {
          this.successCallback('deleteTeam');
          this.$emit('update-teams');
        },
        errorCallback: () => this.errorCallback(this.$t('errorMessages.deleteTeam')),
      });
    },
    onSwitchAuthEmails(switchMark) {
      this.$emit('on-switch-auth-emails', {
        switchMark,
        successCallback: () => {
          this.successCallback(`${switchMark ? 'enable' : 'disable'}AuthEmails`);

          this.$emit('update-company', {
            successCallback: () => this.setAuthEmailState(),
          });
        },
        errorCallback: () => {
          this.toggleVisibleDialog({name: 'enableAuthEmails', state: false});
          this.errorCallback(
            this.$t(`errorMessages.${switchMark ? 'enable' : 'disable'}AuthEmails`)
          );
        },
      });
    },
    successCallback(name) {
      if (this.isDialogVisible[name]) this.toggleVisibleDialog({name, state: false});
      this.successMessage.title = this.$t(`successMessages.${name}`);
      this.toggleVisibleDialog({name: 'success', state: true});
    },
    errorCallback(message) {
      errorAlert(`${message}. ${this.validationErrors}`);
    },
    resetFilters() {
      this.$emit('set-employee-filters', {reset: true, callback: this.onApplyFilters});
    },
  },
};
</script>
<style scoped lang="scss">
.icon-rotate::v-deep .v-icon.mdi-chevron-down {
  transform: rotate(180deg);
}
.company-employees-page {
  &__btn::v-deep .v-btn__content {
    width: -webkit-fill-available;
    justify-content: start;
  }
}
</style>
