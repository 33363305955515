/*
for current page by router/accessRights.js
 */
import {mapGetters} from 'vuex';

import {gettersTypes as userGettersTypes} from '@/store/modules/user/getters';
import {gettersTypes as companyGettersTypes} from '@/store/modules/company/getters';
import {gettersTypes as teamGettersTypes} from '@/store/modules/team/getters';

import {checkAccessToPage, checkCanManage, filterAccessRights} from '@/router/guards';
import {isEmptyObj} from '@/helpers/utils';

export const accessRightsMixin = {
  computed: {
    ...mapGetters({
      isAdmin: userGettersTypes.isAdmin,
      isPlatformAdmin: userGettersTypes.isPlatformAdmin,
      canImpersonate: userGettersTypes.canImpersonate,
      isCreator: companyGettersTypes.isCreator,
      isOrganizer: companyGettersTypes.isOrganizer,
      isManager: companyGettersTypes.isManager,
      isLeader: companyGettersTypes.isLeader,
      isSupervisor: companyGettersTypes.isSupervisor,
      // organizer, supervisor, leader in company
      canTeamsManage: companyGettersTypes.canTeamsManage,
      // supervisor, leader in team
      canManageTeam: teamGettersTypes.canManage,
      isEmployee: companyGettersTypes.isEmployee,
    }),
    currentAccessRights() {
      return filterAccessRights(this.$route.meta);
    },
    canManagePage() {
      if (this.isAdmin || this.isOrganizer) return true;
      if (isEmptyObj(this.currentAccessRights)) return false;
      // admin, organizer, manager all modules
      return Object.values(this.currentAccessRights).every((r) => r === 'admin');
    },
    canManageTeams() {
      if (this.isLeader || this.isSupervisor) return true;
      if (isEmptyObj(this.currentAccessRights)) return false;
      // leader, supervisor
      return (Object.values(this.currentAccessRights) || []).some((r) => r === 'readwrite');
    },
  },
  methods: {
    checkShowTab(moduleName, isCheckCanManage) {
      const rights = filterAccessRights({accessRights: [moduleName]});
      return (
        checkAccessToPage(rights) && (isCheckCanManage ? checkCanManage(rights, moduleName) : true)
      );
    },
    checkAdminModule(moduleName) {
      const rights = filterAccessRights({accessRights: [moduleName]});

      if (isEmptyObj(rights)) return false;
      // admin this module
      return rights[moduleName] === 'admin';
    },
  },
};
