import {connect} from 'vuex-connect';
import AppCompanyEmployees from '@/components/pages/company/employees/CompanyEmployees';

import {actionsTypes as companyActionsTypes} from '@/store/modules/company/actions';
import {gettersTypes as companyGettersTypes} from '@/store/modules/company/getters';
import {actionsTypes as teamActionsTypes} from '@/store/modules/team/actions';
import {mutationTypes as companyMutationTypes} from '@/store/modules/company/mutations';

export const PAGINATION_LIMIT = 20;

export default connect({
  gettersToProps: {
    isAnyModuleEnabled: companyGettersTypes.isAnyModuleEnabled,
    isInterconnectEnabled: companyGettersTypes.isInterconnectEnabled,
    isAuthEmailsEnabled: companyGettersTypes.isAuthEmailsEnabled,
  },
  stateToProps: {
    companySpecializations: (state) => state.company.specializations,
    companyTeams: (state) => state.company.teams,
    employees: (state) => state.company.employees,
    employeesCount: (state) => state.company.employeesCount,
    employeesUsers: (state) => state.company.employeesUsers,
    employeeFilters: (state) => state.company.employeeFilters,
    isLoading: (state) => state.company.isLoading,
    checkTeamTests360Loading: (state) => state.company.checkTeamTests360Loading,
    isSubmitting: (state) => state.company.isSubmitting,
    validationErrors: (state) => state.company.validationErrors,
  },
  methodsToEvents: {
    'update-teams': ({dispatch, getters}) => {
      dispatch(companyActionsTypes.getTeams, getters[companyGettersTypes.id]);
    },
    'get-team-members': ({dispatch}, {teamId, successCallback}) => {
      dispatch(teamActionsTypes.getMembers, teamId).then((employees) => successCallback(employees));
    },
    'check-team-tests-360': ({dispatch, getters}, {teamId, successCallback, errorCallback}) => {
      dispatch(companyActionsTypes.checkTeamTests360, {
        id: getters[companyGettersTypes.id],
        teamId,
      })
        .then((response) => successCallback(response))
        .catch(() => errorCallback());
    },
    'on-import-employees': ({dispatch, getters}, {data, successCallback, errorCallback}) => {
      dispatch(companyActionsTypes.importEmployees, {
        id: getters[companyGettersTypes.id],
        data,
      })
        .then((response) => successCallback(response))
        .catch(() => errorCallback());
    },
    'on-invite-employee': ({dispatch, getters}, {userId, successCallback, errorCallback}) => {
      dispatch(companyActionsTypes.inviteEmployee, {
        id: getters[companyGettersTypes.id],
        userId,
      })
        .then(() => successCallback())
        .catch(() => errorCallback());
    },
    'on-cancel-invite-employee': (
      {dispatch, getters},
      {employeeId, successCallback, errorCallback}
    ) => {
      dispatch(companyActionsTypes.cancelInvitationEmployee, {
        id: getters[companyGettersTypes.id],
        employeeId,
      })
        .then(() => successCallback())
        .catch(() => errorCallback());
    },
    'on-dismiss-employee': ({dispatch, getters}, {employeeId, successCallback, errorCallback}) => {
      dispatch(companyActionsTypes.changeEmployeeStatus, {
        id: getters[companyGettersTypes.id],
        employeeId,
        state: 'dismissed',
      })
        .then(() => successCallback())
        .catch(() => errorCallback());
    },
    'on-reinstate-employee': (
      {dispatch, getters},
      {employeeId, successCallback, errorCallback}
    ) => {
      dispatch(companyActionsTypes.changeEmployeeStatus, {
        id: getters[companyGettersTypes.id],
        employeeId,
        state: 'employee',
      })
        .then(() => successCallback())
        .catch(() => errorCallback());
    },
    'on-delete-team': ({dispatch, getters}, {teamId, successCallback, errorCallback}) => {
      dispatch(companyActionsTypes.deleteTeam, {
        id: getters[companyGettersTypes.id],
        teamId,
      })
        .then(() => successCallback())
        .catch(() => errorCallback());
    },
    'on-switch-auth-emails': (
      {dispatch, getters},
      {switchMark, successCallback, errorCallback}
    ) => {
      dispatch(companyActionsTypes.switchAuthEmails, {
        id: getters[companyGettersTypes.id],
        switchMark,
      })
        .then(() => successCallback())
        .catch(() => errorCallback());
    },
    'update-company': ({dispatch, getters}, {successCallback}) => {
      dispatch(companyActionsTypes.getCompany, getters[companyGettersTypes.id]).then(() =>
        successCallback()
      );
    },

    'get-employees': ({dispatch, getters, state}, {employee_state, pagination}) => {
      dispatch(companyActionsTypes.getEmployeesSimple, {
        id: getters[companyGettersTypes.id],
        simple: true,
        state: employee_state,
        filters: state.company.employeeFilters,
        pagination,
      });
    },

    'set-employee-filters': async function ({commit}, {key, value, reset = false, callback}) {
      if (reset) {
        await commit(companyMutationTypes.resetEmployeeFilters);
      } else {
        await commit(companyMutationTypes.setEmployeeFilters, {
          key,
          value,
        });
      }
      if (callback) callback();
    },

    'get-teams': ({dispatch, getters, commit}) => {
      commit(companyMutationTypes.getTeamsClear);
      dispatch(companyActionsTypes.getTeams, getters[companyGettersTypes.id]);
    },
  },

  lifecycle: {
    mounted: async function () {
      this['get-employees'].call(this, {
        state: 'current',
        pagination: {limit: PAGINATION_LIMIT, offset: 0},
      });
      this['get-teams'].call(this);
    },
  },
})('AppCompanyEmployees', AppCompanyEmployees);
