import {connect} from 'vuex-connect';
import AppSendSurveyDialog from '@/components/pages/company/surveys/dialogs/SendSurveyDialog';

import {gettersTypes as companyGettersTypes} from '@/store/modules/company/getters';
import {actionsTypes as surveyActionsTypes} from '@/store/modules/survey/actions';
import {actionsTypes as companyActionsTypes} from '@/store/modules/company/actions';
import {mutationTypes as companyMutationTypes} from '@/store/modules/company/mutations';

export const PAGINATION_SURVEYS_LIMIT = 20;

export default connect({
  stateToProps: {
    isLoading: (state) => state.survey.isLoading,
    isCompanyLoading: (state) => state.company.isLoading,
    isSubmitting: (state) => state.survey.isSubmitting,
    errors: (state) => state.survey.errors,
    validationErrors: (state) => state.survey.validationErrors,
    recipients: (state) => state.survey.recipients,
    timetable: (state) => state.survey.timetable,
    timetableRecipients: (state) => state.survey.timetableRecipients,
    timetableCount: (state) => state.survey.timetableCount,
    timetableTeams: (state) => state.survey.timetableTeams,
    managedTeams: (state) => state.company.managedTeams,
    employees: (state) => state.company.employees,
    employeesCount: (state) => state.company.employeesCount,
    employeeFilters: (state) => state.company.employeeFilters,
    companySpecializations: (state) => state.company.specializations,
  },
  methodsToEvents: {
    'get-recipients': ({dispatch, getters}, {survey_id, successCallback}) => {
      dispatch(surveyActionsTypes.getRecipients, {
        companyId: getters[companyGettersTypes.id],
        survey_id,
      }).then(() => successCallback());
    },
    'get-timetable': async function (
      {state, dispatch, getters},
      {survey_id, pagination, successCallback, errorCallback}
    ) {
      await dispatch(surveyActionsTypes.getTimetable, {
        companyId: getters[companyGettersTypes.id],
        survey_id,
        filters: state.company.employeeFilters,
        pagination,
      })
        .then(() => successCallback())
        .catch(() => errorCallback());
    },
    'change-timetable': ({dispatch, getters}, {eventData, successCallback, errorCallback}) => {
      dispatch(
        surveyActionsTypes.changeTimetable,
        Object.assign(
          {},
          {
            companyId: getters[companyGettersTypes.id],
          },
          eventData
        )
      )
        .then(() => successCallback())
        .catch(() => errorCallback());
    },

    'get-employees': async function ({dispatch, getters, state}, {pagination, successCallback}) {
      await dispatch(companyActionsTypes.getEmployeesSimple, {
        id: getters[companyGettersTypes.id],
        simple: true,
        state: 'current',
        filters: state.company.employeeFilters,
        pagination,
      }).then(() => {
        if (successCallback) successCallback();
      });
    },

    'set-employee-filters': async function ({commit}, {key, value, reset = false, callback}) {
      if (reset) {
        await commit(companyMutationTypes.resetEmployeeFilters);
      } else {
        await commit(companyMutationTypes.setEmployeeFilters, {
          key,
          value,
        });
      }
      if (callback) callback();
    },
  },
})('AppSendSurveyDialog', AppSendSurveyDialog);
