import companyApi from '@/api/company';
import {mutationTypes} from '@/store/modules/company/mutations';
import {PAGINATION_LIMIT} from '@/components/pages/company/employees/CompanyEmployeesContainer';

export const actionsTypes = {
  getAllCompanyList: '[company] get all company list',
  getCompanies: '[company] get all companies',

  getCompany: '[company] get company',
  getCompanyStatistic: '[company] get statistic',
  getCurrentSpecializations: '[company] get current specializations',
  getSpecializations: '[company] get specializations',
  getCurrentSettings: '[company] get current settings',
  getSettings: '[company] get settings',
  getRecordTypes: '[company] get record types',

  changeSettings: '[company] change settings',
  hideSelfTesting: '[company] hide self-testing',
  hideFeed: '[company] hide feed',
  changeFlags: '[company] change flags',
  changeCompany: '[company] change metadata',
  changeLogo: '[company] change logo',
  changeServices: '[company] change services',

  getPortfolio: '[company] get portfolio',
  createPortfolio: '[company] create portfolio',
  deletePortfolio: '[company] delete portfolio',

  getEmployees: '[company] get employees',
  getEmployeesSimple: '[company] get employees simple',
  getBanEmployees: '[company] get ban employees',
  changeBanEmployees: '[company] change ban employees',
  importEmployees: '[company] import employees',
  getStatusesImportEmployees: '[company] get statuses import employees',
  inviteEmployee: '[company] invite employee',
  inviteEmailEmployee: '[company] invite employee by email',
  cancelInvitationEmployee: '[company] cancel invite employee',
  changeEmployeeStatus: '[company] change employee status',
  changeOrganizers: '[company] change organizers',

  getManagers: '[company] get managers',
  changeManagers: '[company] change managers',
  deleteManager: '[company] delete manager',

  getTests360: '[company] get tests 360',
  getSelfTests: '[company] get self tests',
  getPersonalTests: '[company] get personal tests',
  getEnabledPersonalTests: '[company] get enabled personal tests',
  reEvalLockOfTests360: '[company] re-evaluation lock of tests 360',
  closeTests360: '[company] close tests 360',
  setHiddenReports360: '[company] set hidden reports 360',
  exportTests360: '[company] export tests 360',
  createTests360: '[company] create tests 360',
  deleteTests360: '[company] delete tests 360',

  dryRunRemindersTests360: '[company] dry run reminders about tests 360',
  dryRunRemindersSelfTests: '[company] dry run reminders about self tests',
  dryRunRemindersPersonalTests: '[company] dry run reminders about personal tests',
  createRemindersTests360: '[company] create reminders about tests 360',
  createRemindersSelfTests: '[company] create reminders about self tests',
  createRemindersPersonalTests: '[company] create reminders about personal tests',

  getTeams: '[company] get teams',
  getAllTeams: '[company] get all teams',
  getManagedTeams: '[company] get managed teams',
  getTeamsTree: '[company] get tree of teams',
  checkTeamTests360: '[company] check team tests 360',
  createTeam: '[company] create team',
  changeTeam: '[company] change team',
  deleteTeam: '[company] delete team',

  getTeamGroupsFlat: '[company] get team groups flat',
  getTeamGroups: '[company] get team groups',
  getTeamGroup: '[company] get team group',
  createTeamGroup: '[company] create team group',
  changeTeamGroup: '[company] change team group',
  changeAllTeamGroup: '[company] change all team group',
  deleteTeamGroup: '[company] delete team group',

  exportAdaptation: '[company] export adaptation',
  exportRoadmaps360: '[company] export roadmaps 360',

  getLinks: '[company] get links',
  createLink: '[company] create link',
  changeLink: '[company] change link',
  deleteLink: '[company] delete link',

  switchAuthEmails: '[company] switch auth emails',
  getRoadmaps: '[company] get roadmaps',

  getTargets: '[company] get targets',
  getCuratorsByUser: '[company] get curators by user',
  exportTargets: '[company] export targets',
  startReviewTargets: '[company] start review targets',

  getSubordinateEmployees: '[company] get subordinate employees',
};

const actions = {
  [actionsTypes.getCompanies](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCompaniesStart);
      companyApi
        .getCompanies()
        .then((response) => {
          context.commit(mutationTypes.getCompaniesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCompaniesFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getCompany](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCompanyStart);
      companyApi
        .getCompany(id)
        .then((response) => {
          context.commit(mutationTypes.getCompanySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCompanyFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getAllCompanyList](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getAllCompanyListStart);
      companyApi
        .getAllCompanyList()
        .then((response) => {
          context.commit(mutationTypes.getAllCompanyListSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getAllCompanyListFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getCompanyStatistic](
    context,
    {companyId, statisticType, dateStart, dateEnd, teamIds}
  ) {
    return new Promise((resolve, reject) => {
      const args = {
        date_start: dateStart,
        date_end: dateEnd,
      };
      if (teamIds && teamIds.length > 0) {
        args.team_ids = teamIds.join(',');
      }
      context.commit(mutationTypes.getCompanyStatisticStart, statisticType);
      companyApi
        .getCompanyStatistic(companyId, statisticType, args)
        .then((response) => {
          context.commit(mutationTypes.getCompanyStatisticSuccess, {
            statisticType,
            payload: response.data,
          });
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCompanyStatisticFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getCurrentSpecializations](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCurrentSpecializationsStart);
      companyApi
        .getSpecializations(id)
        .then((response) => {
          context.commit(mutationTypes.getCurrentSpecializationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getCurrentSpecializationsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.getSpecializations](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSpecializationsStart);
      companyApi
        .getSpecializations(id)
        .then((response) => {
          context.commit(mutationTypes.getSpecializationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSpecializationsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getCurrentSettings](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCurrentSettingsStart);
      companyApi
        .getSettings(id)
        .then((response) => {
          context.commit(mutationTypes.getCurrentSettingsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCurrentSettingsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getSettings](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSettingsStart);
      companyApi
        .getSettings(id)
        .then((response) => {
          context.commit(mutationTypes.getSettingsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSettingsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getRecordTypes](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getRecordTypesStart);
      companyApi
        .getRecordTypes(id)
        .then((response) => {
          context.commit(mutationTypes.getRecordTypesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getRecordTypesFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.changeSettings](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeSettingsStart);
      companyApi
        .changeSettings(id, data)
        .then((response) => {
          context.commit(mutationTypes.changeSettingsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeSettingsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.hideSelfTesting](context, {id, state}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.hideSelfTestingStart);
      companyApi
        .hideSelfTesting(id, state)
        .then((response) => {
          context.commit(mutationTypes.hideSelfTestingSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.hideSelfTestingFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.hideFeed](context, {id, state}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.hideFeedStart);
      companyApi
        .hideFeed(id, state)
        .then((response) => {
          context.commit(mutationTypes.hideFeedSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.hideFeedFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeFlags](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeFlagsStart);
      companyApi
        .changeFlags(id, data)
        .then((response) => {
          context.commit(mutationTypes.changeFlagsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeFlagsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeCompany](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeCompanyStart);
      companyApi
        .changeCompany(id, data)
        .then((response) => {
          context.commit(mutationTypes.changeCompanySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeCompanyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeLogo](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeLogoStart);
      companyApi
        .changeLogo(id, data)
        .then((response) => {
          context.commit(mutationTypes.changeLogoSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeLogoFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeServices](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeServicesStart);
      companyApi
        .changeServices(id, data)
        .then((response) => {
          context.commit(mutationTypes.changeServicesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeServicesFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getPortfolio](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getPortfolioStart);
      companyApi
        .getPortfolio(id)
        .then((response) => {
          context.commit(mutationTypes.getPortfolioSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getPortfolioFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createPortfolio](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createPortfolioStart);
      companyApi
        .createPortfolio(id, data)
        .then((response) => {
          context.commit(mutationTypes.createPortfolioSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createPortfolioFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deletePortfolio](context, {id, portfolioId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deletePortfolioStart);
      companyApi
        .deletePortfolio(id, portfolioId)
        .then((response) => {
          context.commit(mutationTypes.deletePortfolioSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deletePortfolioFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getEmployees](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getEmployeesStart);
      companyApi
        .getEmployees(id)
        .then((response) => {
          context.commit(mutationTypes.getEmployeesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getEmployeesFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getEmployeesSimple](
    context,
    {
      id,
      simple = false,
      state = 'employee',
      withTeams = false,
      excludeIds = [], // user_id
      autofillTeamId = 0,
      autofillUserId = 0, // user_id
      autoFillExpertType = undefined,
      filters = {},
      pagination: {limit = PAGINATION_LIMIT, offset = 0},
    }
  ) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getEmployeesStart);

      const args = {};

      if (filters && filters.teamIds && filters.teamIds.length > 0) {
        args.team_ids = filters.teamIds.join(',');
      }
      if (filters && filters.specializationIds && filters.specializationIds.length > 0) {
        args.specialization_ids = filters.specializationIds.join(',');
      }
      if (filters && filters.employeeId) {
        args.employee_id = filters.employeeId;
      }
      if (filters && filters.employeeName) {
        args.employee_name = filters.employeeName;
      }
      if (withTeams) {
        args.with_teams = withTeams;
      }
      if (excludeIds && excludeIds.length) {
        args.exclude_ids = excludeIds.join(',');
      }
      if (autofillTeamId) {
        args.autofill_team_id = autofillTeamId;
      }
      if (autofillUserId) {
        args.autofill_user_id = autofillUserId;
      }
      if (autoFillExpertType) {
        args.autofill_expert_type = autoFillExpertType;
      }

      args.limit = limit;
      args.offset = offset;

      companyApi
        .getEmployees(id, simple, state, args)
        .then((response) => {
          context.commit(mutationTypes.getEmployeesSimpleSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getEmployeesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getBanEmployees](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getBanEmployeesStart);
      companyApi
        .getBanEmployees(id)
        .then((response) => {
          context.commit(mutationTypes.getBanEmployeesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getBanEmployeesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeBanEmployees](context, {id, banned}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeBanEmployeesStart);
      companyApi
        .changeBanEmployees(id, banned)
        .then((response) => {
          context.commit(mutationTypes.changeBanEmployeesSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeBanEmployeesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.importEmployees](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.importEmployeesStart);
      companyApi
        .importEmployees(id, data)
        .then((response) => {
          context.commit(mutationTypes.importEmployeesSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.importEmployeesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getStatusesImportEmployees](context, packNumber) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getStatusesImportEmployeesStart);
      companyApi
        .getStatusesImportEmployees(packNumber)
        .then((response) => {
          context.commit(mutationTypes.getStatusesImportEmployeesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getStatusesImportEmployeesFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.inviteEmployee](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.inviteEmployeeStart);
      companyApi
        .inviteEmployee(id, userId)
        .then((response) => {
          context.commit(mutationTypes.inviteEmployeeSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.inviteEmployeeFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.inviteEmailEmployee](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.inviteEmailEmployeeStart);
      companyApi
        .inviteEmailEmployee(id, data)
        .then((response) => {
          context.commit(mutationTypes.inviteEmailEmployeeSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.inviteEmailEmployeeFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.cancelInvitationEmployee](context, {id, employeeId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.cancelInvitationEmployeeStart);
      companyApi
        .cancelInvitationEmployee(id, employeeId)
        .then((response) => {
          context.commit(mutationTypes.cancelInvitationEmployeeSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.cancelInvitationEmployeeFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.changeEmployeeStatus](context, {id, employeeId, state}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeEmployeeStatusStart);
      companyApi
        .changeEmployeeStatus(id, employeeId, state)
        .then((response) => {
          context.commit(mutationTypes.changeEmployeeStatusSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeEmployeeStatusFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.changeOrganizers](context, {id, employeeIds}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeOrganizersStart);
      companyApi
        .changeOrganizers(id, employeeIds)
        .then((response) => {
          context.commit(mutationTypes.changeOrganizersSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeOrganizersFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getManagers](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getManagersStart);
      companyApi
        .getManagers(id)
        .then((response) => {
          context.commit(mutationTypes.getManagersSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getManagersFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeManagers](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeManagersStart);
      companyApi
        .changeManagers(id, data)
        .then((response) => {
          context.commit(mutationTypes.changeManagersSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeManagersFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteManager](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteManagerStart);
      companyApi
        .deleteManager(id, userId)
        .then((response) => {
          context.commit(mutationTypes.deleteManagerSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteManagerFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getTests360](context, {id, forceIncludeSelftest}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTests360Start);
      companyApi
        .getTests360(id, forceIncludeSelftest)
        .then((response) => {
          context.commit(mutationTypes.getTests360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTests360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getSelfTests](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSelfTestsStart);
      companyApi
        .getSelfTests(id)
        .then((response) => {
          context.commit(mutationTypes.getSelfTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSelfTestsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getPersonalTests](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getPersonalTestsStart);
      companyApi
        .getPersonalTests(id)
        .then((response) => {
          context.commit(mutationTypes.getPersonalTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getPersonalTestsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getEnabledPersonalTests](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getEnabledPersonalTestsStart);
      companyApi
        .getEnabledPersonalTests(id)
        .then((response) => {
          context.commit(mutationTypes.getEnabledPersonalTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getEnabledPersonalTestsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.reEvalLockOfTests360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.reEvalLockOfTests360Start);
      companyApi
        .reEvalLockOfTests360(id, data)
        .then((response) => {
          context.commit(mutationTypes.reEvalLockOfTests360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.reEvalLockOfTests360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.closeTests360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.closeTests360Start);
      companyApi
        .closeTests360(id, data)
        .then((response) => {
          context.commit(mutationTypes.closeTests360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.closeTests360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.setHiddenReports360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.setHiddenReports360Start);
      companyApi
        .setHiddenReports360(id, data)
        .then((response) => {
          context.commit(mutationTypes.setHiddenReports360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.setHiddenReports360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.exportTests360](context, {id, method, type, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.exportTests360Start);
      companyApi
        .exportTests360(id, method, type, data)
        .then((response) => {
          context.commit(mutationTypes.exportTests360Success);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.exportTests360Failure, result.response.status);
          reject(result);
        });
    });
  },
  [actionsTypes.createTests360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createTests360Start);
      companyApi
        .createTests360(id, data)
        .then((response) => {
          context.commit(mutationTypes.createTests360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createTests360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteTests360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteTests360Start);
      companyApi
        .deleteTests360(id, data)
        .then((response) => {
          context.commit(mutationTypes.deleteTests360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteTests360Failure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.dryRunRemindersTests360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.dryRunRemindersTests360Start);
      companyApi
        .dryRunRemindersTests360(id, data)
        .then((response) => {
          context.commit(mutationTypes.dryRunRemindersTests360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.dryRunRemindersTests360Failure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.dryRunRemindersSelfTests](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.dryRunRemindersSelfTestsStart);
      companyApi
        .dryRunRemindersSelfTests(id, data)
        .then((response) => {
          context.commit(mutationTypes.dryRunRemindersSelfTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.dryRunRemindersSelfTestsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.dryRunRemindersPersonalTests](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.dryRunRemindersPersonalTestsStart);
      companyApi
        .dryRunRemindersPersonalTests(id, data)
        .then((response) => {
          context.commit(mutationTypes.dryRunRemindersPersonalTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.dryRunRemindersPersonalTestsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.createRemindersTests360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createRemindersTests360Start);
      companyApi
        .createRemindersTests360(id, data)
        .then((response) => {
          context.commit(mutationTypes.createRemindersTests360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.createRemindersTests360Failure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.createRemindersSelfTests](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createRemindersSelfTestsStart);
      companyApi
        .createRemindersSelfTests(id, data)
        .then((response) => {
          context.commit(mutationTypes.createRemindersSelfTestsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.createRemindersSelfTestsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.createRemindersPersonalTests](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createRemindersPersonalTestsStart);
      companyApi
        .createRemindersPersonalTests(id, data)
        .then((response) => {
          context.commit(mutationTypes.createRemindersPersonalTestsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.createRemindersPersonalTestsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },

  [actionsTypes.getTeams](context, id, simple = false) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTeamsStart);
      companyApi
        .getTeams(id, false, simple)
        .then((response) => {
          context.commit(mutationTypes.getTeamsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTeamsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getAllTeams](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getAllTeamsStart);
      companyApi
        .getTeams(id, true)
        .then((response) => {
          context.commit(mutationTypes.getAllTeamsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getAllTeamsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getManagedTeams](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getManagedTeamsStart);
      companyApi
        .getManagedTeams(id)
        .then((response) => {
          context.commit(mutationTypes.getManagedTeamsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getManagedTeamsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getTeamsTree](context, {companyId, teamIds}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTeamsTreeStart);
      companyApi
        .getTeamsTree(companyId, teamIds)
        .then((response) => {
          context.commit(mutationTypes.getTeamsTreeSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTeamsTreeFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.checkTeamTests360](context, {id, teamId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.checkTeamTests360Start);
      companyApi
        .checkTeamTests360(id, teamId)
        .then((response) => {
          context.commit(mutationTypes.checkTeamTests360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.checkTeamTests360Failure);
          reject(result);
        });
    });
  },
  [actionsTypes.createTeam](context, {id, teamName}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createTeamStart);
      companyApi
        .createTeam(id, teamName)
        .then((response) => {
          context.commit(mutationTypes.createTeamSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createTeamFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeTeam](context, {id, teamId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeTeamStart);
      companyApi
        .changeTeam(id, teamId, data)
        .then((response) => {
          context.commit(mutationTypes.changeTeamSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeTeamFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteTeam](context, {id, teamId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteTeamStart);
      companyApi
        .deleteTeam(id, teamId)
        .then((response) => {
          context.commit(mutationTypes.deleteTeamSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteTeamFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getTeamGroupsFlat](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTeamGroupsFlatStart);
      companyApi
        .getTeamGroupsFlat(id)
        .then((response) => {
          context.commit(mutationTypes.getTeamGroupsFlatSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTeamGroupsFlatFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getTeamGroups](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTeamGroupsStart);
      companyApi
        .getTeamGroups(id)
        .then((response) => {
          context.commit(mutationTypes.getTeamGroupsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTeamGroupsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getTeamGroup](context, {id, teamGroupId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTeamGroupStart);
      companyApi
        .getTeamGroup(id, teamGroupId)
        .then((response) => {
          context.commit(mutationTypes.getTeamGroupSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTeamGroupFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createTeamGroup](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createTeamGroupStart);
      companyApi
        .createTeamGroup(id, data)
        .then((response) => {
          context.commit(mutationTypes.createTeamGroupSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createTeamGroupFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeTeamGroup](context, {id, teamGroupId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeTeamGroupStart);
      companyApi
        .changeTeamGroup(id, teamGroupId, data)
        .then((response) => {
          context.commit(mutationTypes.changeTeamGroupSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeTeamGroupFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeAllTeamGroup](context, {id, teamGroupId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeAllTeamGroupStart);
      companyApi
        .changeAllTeamGroup(id, teamGroupId, data)
        .then((response) => {
          context.commit(mutationTypes.changeAllTeamGroupSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeAllTeamGroupFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteTeamGroup](context, {id, teamGroupId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteTeamGroupStart);
      companyApi
        .deleteTeamGroup(id, teamGroupId)
        .then((response) => {
          context.commit(mutationTypes.deleteTeamGroupSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteTeamGroupFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.exportAdaptation](context, {id, dateFrom, dateTo}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.exportAdaptationStart);
      companyApi
        .exportAdaptation(id, dateFrom, dateTo)
        .then((response) => {
          context.commit(mutationTypes.exportAdaptationSuccess);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.exportAdaptationFailure, result.response.status);
          reject(result);
        });
    });
  },
  [actionsTypes.exportRoadmaps360](context, {id, method, type, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.exportRoadmaps360Start);
      companyApi
        .exportRoadmaps360(id, method, type, data)
        .then((response) => {
          context.commit(mutationTypes.exportRoadmaps360Success);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.exportRoadmaps360Failure, result.response.status);
          reject(result);
        });
    });
  },

  [actionsTypes.getLinks](context, {id, linkType}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getLinksStart);
      companyApi
        .getLinks(id, linkType)
        .then((response) => {
          context.commit(mutationTypes.getLinksSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getLinksFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createLink](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createLinkStart);
      companyApi
        .createLink(id, data)
        .then((response) => {
          context.commit(mutationTypes.createLinkSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createLinkFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeLink](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeLinkStart);
      companyApi
        .changeLink(id, data)
        .then((response) => {
          context.commit(mutationTypes.changeLinkSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeLinkFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteLink](context, {id, linkId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteLinkStart);
      companyApi
        .deleteLink(id, linkId)
        .then((response) => {
          context.commit(mutationTypes.deleteLinkSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteLinkFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.switchAuthEmails](context, {id, switchMark}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.switchAuthEmailsStart);
      companyApi
        .switchAuthEmails(id, switchMark)
        .then((response) => {
          context.commit(mutationTypes.switchAuthEmailsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.switchAuthEmailsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getRoadmaps](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getRoadmapsStart);
      companyApi
        .getRoadmaps(id)
        .then((response) => {
          context.commit(mutationTypes.getRoadmapsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getRoadmapsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getTargets](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTargetsStart);
      companyApi
        .getTargets(id)
        .then((response) => {
          context.commit(mutationTypes.getTargetsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTargetsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getCuratorsByUser](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCuratorsByUserStart);
      companyApi
        .getCuratorsByUser(id, userId)
        .then((response) => {
          context.commit(mutationTypes.getCuratorsByUserSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCuratorsByUserFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.exportTargets](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.exportTargetsStart);
      companyApi
        .exportTargets(id)
        .then((response) => {
          context.commit(mutationTypes.exportTargetsSuccess);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.exportTargetsFailure, result.response.status);
          reject(result);
        });
    });
  },
  [actionsTypes.startReviewTargets](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.startReviewTargetsStart);
      companyApi
        .startReviewTargets(id)
        .then((response) => {
          context.commit(mutationTypes.startReviewTargetsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.startReviewTargetsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getSubordinateEmployees](context, {companyId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSubordinateEmployeesStart);
      companyApi
        .getSubordinateEmployees(companyId)
        .then((response) => {
          context.commit(mutationTypes.getSubordinateEmployeesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSubordinateEmployeesFailure);
          reject(result);
        });
    });
  },
};

export default actions;
