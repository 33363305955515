<i18n>
{
  "ru": {
    "sendSurveyTitle": "Назначение опроса",
    "sendTestTitle": "Назначение теста",
    "sendSurveyDescription": "Пользователи получат{first}письмо в 10:00 в дату, указанную как старт проведения опроса. Если начало опроса сегодня или ранее, то выбранные пользователи получат письма сразу, после того как вы нажмёте на кнопку «Готово»",
    "sendTestDescription": "Пользователи получат{first}письмо в 10:00 в дату, указанную как старт проведения теста. Если начало теста сегодня или ранее, то выбранные пользователи получат письма сразу, после того как вы нажмёте на кнопку «Готово»",
    "tabs": {
      "assign": "Назначить",
      "recipients": "Назначенные"
    },
    "confirmDeleteRecipients": {
      "title": "Удалить сотрудников?",
      "text": "Сотрудники будут исключены из получателей опроса",
      "textTest": "Сотрудники будут исключены из получателей теста"
    },
    "confirmDeleteAllRecipients": {
      "title": "Удалить всех сотрудников?",
      "text": "Все сотрудники компании будут исключены из получателей опроса",
      "textTest": "Все сотрудники компании будут исключены из получателей теста"
    },
    "confirmAddAllRecipients": {
      "title": "Добавить всех сотрудников?",
      "text": "Все сотрудники компании будут добавлены в получатели опроса",
      "textTest": "Все сотрудники компании будут добавлены в получатели теста"
    },
    "noCompanyEmployees": "Для назначения опроса добавьте сотрудников в компанию. Для этого перейдите на вкладку",
    "noEmployees": "Все сотрудники уже участвуют в опросе",
    "noEmployeesTest": "Все сотрудники уже участвуют в тестировании",
    "noRecipients": "Ещё ни один пользователь, подходящий под ваш запрос не принял участие в опросе. Пригласить участников вы можете на вкладке «Назначить»",
    "noRecipientsTest": "Ещё ни один пользователь, подходящий под ваш запрос не принял участие в тесте. Пригласить участников вы можете на вкладке «Назначить»",
    "successMessages": {
      "sendSurvey": "Опрос успешно отправлен",
      "changeTimetable": "Опрос успешно изменён",
      "sendTest": "Тест успешно отправлен",
      "changeTimetableTest": "Тест успешно изменён"
    },
    "errorMessages": {
      "sendSurvey": "Не удалось отправить опрос",
      "getTimetable": "Нет команд в подчинении",
      "changeTimetable": "Не удалось изменить опрос",
      "sendSurveyTest": "Не удалось отправить тест",
      "changeTimetableTest": "Не удалось изменить тест"
    },
    "allCompany": "На всю компанию",
    "fromAllCompany": "Удалить всех",
    "first": " первое "
  },
  "en": {
    "sendSurveyTitle": "Assign the survey",
    "sendTestTitle": "Assign the test",
    "sendSurveyDescription": "Users will receive{first}email at 10:00 on the date specified as the start of the survey. If the survey starts today or earlier, the selected users will receive emails immediately after you click on the «Done» button",
    "sendTestDescription": "Users will receive{first}email at 10:00 on the date specified as the start of the test. If the test starts today or earlier, the selected users will receive emails immediately after you click on the «Done» button",
    "tabs": {
      "assign": "Assign",
      "recipients": "Assignee"
    },
    "confirmDeleteRecipients": {
      "title": "Delete the employees?",
      "text": "Employees will be excluded from the survey recipients",
      "textTest": "Employees will be excluded from the test recipients"
    },
    "confirmDeleteAllRecipients": {
      "title": "Delete all employees?",
      "text": "All employees of the company will be excluded from the survey recipients",
      "textTest": "All employees of the company will be excluded from the test recipients"
    },
    "confirmAddAllRecipients": {
      "title": "Add all employees?",
      "text": "All employees of the company will be added to the recipients of the survey",
      "textTest": "All employees of the company will be added to the recipients of the test"
    },
    "noCompanyEmployees": "To assign the survey, add employees to the company. To do this, go to the tab",
    "noEmployees": "All employees are already participating in the survey",
    "noEmployeesTest": "All employees are already participating in the testing",
    "noRecipients": "Not a single user has taken part in the survey yet. You can invite participants on the «Assign» tab",
    "noRecipientsTest": "Not a single user has taken part in the test yet. You can invite participants on the «Assign» tab",
    "successMessages": {
      "sendSurvey": "The survey sent successfully",
      "changeTimetable": "The survey changed successfully",
      "sendSurveyTest": "The test sent successfully",
      "changeTimetableTest": "The test changed successfully"
    },
    "errorMessages": {
      "sendSurvey": "Failed to send the survey",
      "getTimetable": "There are no teams in subordination",
      "changeTimetable": "Failed to change the survey",
      "sendTest": "Failed to send the test",
      "changeTimetableTest": "Failed to change the test"
    },
    "allCompany": "For the whole company",
    "fromAllCompany": "Delete all",
    "first": " the first "
  }
}
</i18n>
<template>
  <div class="send-survey-dialog">
    <v-dialog
      v-model="isVisibleState"
      :max-width="1000"
      :fullscreen="$vuetify.breakpoint.mobile"
      :scrollable="!$vuetify.breakpoint.mobile"
    >
      <v-card class="position-relative">
        <v-btn
          absolute
          plain
          small
          icon
          top
          right
          :disabled="isSubmitting"
          @click="isVisibleState = false"
        >
          <v-icon v-text="'mdi-close'"></v-icon>
        </v-btn>
        <v-card-title class="flex-column align-start">
          <app-title size="normal" weight="bold">{{
            isTest ? $t('sendTestTitle') : $t('sendSurveyTitle')
          }}</app-title>
          <p>
            <span class="grey--text">{{ isTest ? $t('test') : $t('survey') }}:&ensp;</span>
            <span>{{ survey.title }}</span>
          </p>
        </v-card-title>
        <v-card-subtitle class="pt-0">
          <v-container fluid pa-0>
            <p class="lh-normal">
              {{
                isTest
                  ? $t('sendTestDescription', {first: isPeriodic ? $t('first') : ' '})
                  : $t('sendSurveyDescription', {first: isPeriodic ? $t('first') : ' '})
              }}
            </p>
            <v-tabs
              v-model="activeTab"
              background-color="info"
              slider-color="blue"
              color="blue"
              active-class="blue--text"
              show-arrows
              @change="onChangeTab"
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.key"
                :disabled="isLoading || isCompanyLoading || isSubmitting"
                >{{ tab.title }}</v-tab
              >
            </v-tabs>
            <v-tabs-items v-model="activeTab" class="mt-3 pt-2">
              <v-tab-item>
                <v-row align="center">
                  <v-col cols="12" sm="6" xl="4">
                    <v-text-field
                      v-model="employeeName"
                      icolor="blue"
                      dense
                      outlined
                      clearable
                      hide-details
                      :disabled="isLoading || isCompanyLoading || isSubmitting"
                      :label="$t('searchByFullName')"
                      :placeholder="`${$t('search')}...`"
                      @click:clear="clearEmployee"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" xl="4">
                    <v-autocomplete
                      v-model="teamIds"
                      :items="managedTeams"
                      item-text="team_name"
                      item-value="team_id"
                      item-color="blue"
                      dense
                      outlined
                      clearable
                      hide-details
                      :disabled="isLoading || isCompanyLoading || isSubmitting"
                      :label="$t('searchByTeam')"
                      :placeholder="`${$t('search')}...`"
                      @change="openAccordion"
                      @click:clear="clearTeams"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" xl="4">
                    <v-autocomplete
                      v-model="specializationIds"
                      :items="localTeamsSpecializations"
                      item-text="name"
                      item-value="code"
                      item-color="blue"
                      dense
                      outlined
                      clearable
                      hide-details
                      :disabled="isLoading || isCompanyLoading || isSubmitting"
                      :label="$t('userMetadata.specialization')"
                      :placeholder="`${$t('search')}...`"
                      @click:clear="clearSpecs"
                    >
                      <template #item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row align="baseline">
                  <v-col cols="12" sm="auto">
                    <span :class="{'grey--text': !form.employees.length}">
                      {{
                        declOfNum(form.employees.length, $t('quantity.selected.masculine'))
                      }}&nbsp;{{ form.employees.length }}&nbsp;{{
                        declOfNum(form.employees.length, $t('quantity.people'))
                      }}
                    </span>
                  </v-col>

                  <v-col cols="12" sm="auto">
                    <v-btn
                      outlined
                      x-small
                      color="blue"
                      :disabled="selectAllEmployeesState"
                      block
                      @click="selectAllEmployees"
                    >
                      {{ $t('buttons.selectAllPeople') }}
                    </v-btn>
                  </v-col>

                  <v-col cols="12" sm="auto">
                    <v-btn
                      outlined
                      x-small
                      :disabled="!form.employees.length"
                      block
                      @click="form.employees = []"
                    >
                      {{ $t('buttons.deselect') }}
                    </v-btn>
                  </v-col>

                  <v-col cols="auto" class="ms-auto">
                    <v-btn text color="blue" x-small @click="toggleOpenAccordion(!isOpenAccordion)">
                      {{ isOpenAccordion ? $t('buttons.collapseAll') : $t('buttons.expandAll') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <v-row align="center">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="employeeName"
                      icolor="blue"
                      dense
                      outlined
                      clearable
                      hide-details
                      :disabled="isLoading || isCompanyLoading || isSubmitting"
                      :label="$t('searchByFullName')"
                      :placeholder="`${$t('search')}...`"
                      @click:clear="clearEmployee"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="teamIds"
                      :items="filteredManagedTeams"
                      item-text="team_name"
                      item-value="team_id"
                      item-color="blue"
                      dense
                      outlined
                      clearable
                      hide-details
                      :disabled="isLoading || isCompanyLoading || isSubmitting"
                      :label="$t('searchByTeam')"
                      :placeholder="`${$t('search')}...`"
                      @change="openAccordion"
                      @click:clear="clearTeams"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row align="baseline">
                  <v-col cols="12" sm="auto">
                    <span :class="{'grey--text': !selectedRecipients.length}">
                      {{
                        declOfNum(selectedRecipients.length, $t('quantity.selected.masculine'))
                      }}&nbsp;{{ selectedRecipients.length }}&nbsp;{{
                        declOfNum(selectedRecipients.length, $t('quantity.people'))
                      }}
                    </span>
                  </v-col>

                  <v-col cols="12" sm="auto">
                    <v-btn
                      outlined
                      x-small
                      color="blue"
                      :disabled="selectAllRecipientsState"
                      block
                      @click="selectAllRecipients"
                    >
                      {{ $t('buttons.selectAllPeople') }}
                    </v-btn>
                  </v-col>

                  <v-col cols="12" sm="auto">
                    <v-btn
                      outlined
                      x-small
                      :disabled="!selectedRecipients.length"
                      block
                      @click="selectedRecipients = []"
                    >
                      {{ $t('buttons.deselect') }}
                    </v-btn>
                  </v-col>

                  <v-col cols="12" sm="auto" class="ms-auto">
                    <v-btn
                      outlined
                      x-small
                      :disabled="!selectedRecipients.length"
                      block
                      color="warning"
                      @click="deleteRecipients"
                      >{{ $t('buttons.delete') }}</v-btn
                    >
                  </v-col>

                  <v-col v-if="deletedRecipients.length" cols="12" sm="auto">
                    <v-btn outlined x-small block color="blue" @click="deletedRecipients = []">{{
                      $t('buttons.recover')
                    }}</v-btn>
                  </v-col>

                  <v-col cols="12" sm="auto">
                    <v-btn text color="blue" x-small @click="toggleOpenAccordion(!isOpenAccordion)">
                      {{ isOpenAccordion ? $t('buttons.collapseAll') : $t('buttons.expandAll') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-subtitle>
        <v-card-text>
          <div class="position-relative d-flex align-center justify-center">
            <app-loading
              v-if="isLoading || isSubmitting || isCompanyLoading"
              type="inline"
            ></app-loading>
          </div>
          <v-container :class="{disabled: isCompanyLoading}" fluid pa-0>
            <p v-if="locationErrors" class="error-message">{{ locationErrors }}</p>
            <v-tabs-items v-model="activeTab">
              <v-tab-item>
                <transition name="fade" mode="out-in">
                  <div v-if="isUpdated">
                    <template v-if="employees && employees.length">
                      <v-form
                        v-if="employees.length && employeesCount !== timetableCount"
                        ref="form"
                        :class="{'is-submitting': isSubmitting}"
                      >
                        <div v-if="localEmployees.length">
                          <v-expansion-panels
                            :key="localEmployees.length"
                            v-model="accordion"
                            accordion
                            multiple
                            readonly
                          >
                            <v-expansion-panel
                              v-for="(team, teamIndex) in localEmployees"
                              :key="team.id"
                            >
                              <v-expansion-panel-header>
                                <v-row align="center">
                                  <v-col cols="12" sm="auto" class="send-survey-dialog__row-header">
                                    <span
                                      class="app-text-family--bold"
                                      @click="toggleOpenAccordionItem(teamIndex)"
                                      >{{ team.name }}</span
                                    >
                                  </v-col>
                                  <v-col cols="auto">
                                    <v-btn
                                      outlined
                                      x-small
                                      block
                                      color="blue"
                                      :disabled="checkSelectAllTeam(team.employees)"
                                      @click="selectAllTeam(team.employees)"
                                    >
                                      {{ $t('buttons.selectAllPeople') }}
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="auto">
                                    <v-btn
                                      outlined
                                      x-small
                                      block
                                      :disabled="!checkSelectAllTeam(team.employees)"
                                      @click="deselectAllTeam(team.employees)"
                                    >
                                      {{ $t('buttons.deselect') }}
                                    </v-btn>
                                  </v-col>
                                </v-row>
                                <template #actions>
                                  <v-btn icon @click="toggleOpenAccordionItem(teamIndex)">
                                    <v-icon>$expand</v-icon>
                                  </v-btn>
                                </template>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-list color="info" class="app-text-size--small">
                                  <template v-for="(employee, i) in team.employees">
                                    <v-list-item :key="employee[userIdField]">
                                      <v-row align="center">
                                        <v-col cols="12" md="6">
                                          <v-checkbox
                                            v-model="form.employees"
                                            :rules="[rules.required]"
                                            :value="employee[userIdField]"
                                            dense
                                            hide-details
                                            color="blue"
                                            class="pa-0 ma-0"
                                          >
                                            <template #label>
                                              <span
                                                :class="{
                                                  'app-text-family--bold':
                                                    $vuetify.breakpoint.mobile,
                                                }"
                                                >{{ employee.full_name }}</span
                                              >
                                            </template>
                                          </v-checkbox>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <div
                                            class="d-flex"
                                            :class="
                                              $vuetify.breakpoint.mobile
                                                ? 'align-start'
                                                : 'align-center'
                                            "
                                          >
                                            <v-icon
                                              class="me-2"
                                              small
                                              v-text="'mdi-briefcase-outline'"
                                            ></v-icon>
                                            <template v-if="getSpecializations(employee).length">
                                              <template v-if="$vuetify.breakpoint.mobile">
                                                <span>{{
                                                  getSpecializations(employee).join(', ')
                                                }}</span>
                                              </template>
                                              <template v-else>
                                                <span>{{ getSpecializations(employee)[0] }}</span>
                                                <v-tooltip
                                                  v-if="getSpecializations(employee).length > 1"
                                                  top
                                                  color="secondary"
                                                >
                                                  <template #activator="{on, attrs}">
                                                    <v-icon
                                                      small
                                                      color="blue"
                                                      class="ms-2"
                                                      v-bind="attrs"
                                                      v-on="on"
                                                      v-text="'mdi-dots-horizontal-circle-outline'"
                                                    ></v-icon>
                                                  </template>
                                                  <div class="d-flex flex-column">
                                                    <span
                                                      v-for="(spec, index) in getSpecializations(
                                                        employee,
                                                        true
                                                      )"
                                                      :key="index"
                                                      class="app-text-size--extra-small my-1"
                                                      >{{ spec }}</span
                                                    >
                                                  </div>
                                                </v-tooltip>
                                              </template>
                                            </template>
                                            <span v-else class="grey--text">{{
                                              $t('stubs.notSpecified')
                                            }}</span>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </v-list-item>
                                    <v-divider
                                      v-if="i + 1 < team.employees.length"
                                      :key="`divider-${employee[userIdField]}`"
                                      class="my-3"
                                    ></v-divider>
                                  </template>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>

                          <v-pagination
                            v-show="employeesCount > localEmployees.length"
                            :value="pagination.page"
                            color="blue darken-5"
                            :total-visible="7"
                            :length="Math.ceil(pagination.total / pagination.limit)"
                            @input="onPaginate"
                          ></v-pagination>
                        </div>
                        <p v-else class="grey--text">{{ $t('stubs.nothingFound') }}</p>
                      </v-form>
                      <p v-else class="grey--text">
                        {{ isTest ? $t('noEmployeesTest') : $t('noEmployees') }}
                      </p>
                    </template>
                    <p v-else class="grey--text">
                      <span>{{ $t('noCompanyEmployees') }} </span>
                      <router-link
                        :to="{name: $routeNames.main.company.companyEmployees}"
                        class="blue--text"
                        >«{{ $t('employees') }}»</router-link
                      >
                    </p>
                  </div>
                </transition>
              </v-tab-item>
              <v-tab-item>
                <transition name="fade" mode="out-in">
                  <div v-if="isUpdated && !isLoading">
                    <template v-if="localRecipients.length">
                      <div v-if="localTeams.length">
                        <v-expansion-panels
                          :key="localTeams.length"
                          v-model="accordion"
                          accordion
                          multiple
                          readonly
                        >
                          <v-expansion-panel
                            v-for="(team, teamIndex) in localTeams.filter((t) => {
                              return t.employees && t.employees.length > 0;
                            })"
                            :key="team.id"
                          >
                            <v-expansion-panel-header>
                              <v-row align="center">
                                <v-col cols="12" sm="auto">
                                  <span
                                    class="app-text-family--bold"
                                    @click="toggleOpenAccordionItem(teamIndex)"
                                    >{{ team.name }}</span
                                  >
                                </v-col>
                                <v-col cols="auto">
                                  <v-btn
                                    outlined
                                    x-small
                                    block
                                    color="blue"
                                    :disabled="checkSelectAllTeam(team.employees)"
                                    @click="selectAllTeam(team.employees)"
                                  >
                                    {{ $t('buttons.selectAllPeople') }}
                                  </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                  <v-btn
                                    outlined
                                    x-small
                                    block
                                    :disabled="!checkSelectAllTeam(team.employees)"
                                    @click="deselectAllTeam(team.employees)"
                                  >
                                    {{ $t('buttons.deselect') }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <template #actions>
                                <v-btn icon @click="toggleOpenAccordionItem(teamIndex)">
                                  <v-icon>$expand</v-icon>
                                </v-btn>
                              </template>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                              <v-list color="info" class="app-text-size--small">
                                <template v-for="(recipient, i) in team.employees">
                                  <v-list-item :key="recipient.user_id">
                                    <v-row align="center">
                                      <v-col cols="12" md="6">
                                        <v-checkbox
                                          v-if="!recipient.deleted"
                                          v-model="selectedRecipients"
                                          :rules="[rules.required]"
                                          :value="recipient.user_id"
                                          dense
                                          hide-details
                                          color="blue"
                                          class="pa-0 ma-0"
                                        >
                                          <template #label>
                                            <span
                                              :class="{
                                                'app-text-family--bold': $vuetify.breakpoint.mobile,
                                              }"
                                              >{{ recipient.full_name }}</span
                                            >
                                          </template>
                                        </v-checkbox>
                                        <v-checkbox
                                          v-else
                                          v-model="deletedRecipients"
                                          :rules="[rules.required]"
                                          :value="recipient.user_id"
                                          dense
                                          hide-details
                                          disabled
                                          color="blue"
                                          class="pa-0 ma-0 app-text-decoration--strike"
                                        >
                                          <template #label>
                                            <span
                                              :class="{
                                                'app-text-family--bold': $vuetify.breakpoint.mobile,
                                              }"
                                              >{{ recipient.full_name }}</span
                                            >
                                          </template>
                                        </v-checkbox>
                                      </v-col>
                                      <v-col cols="12" md="6">
                                        <div
                                          class="d-flex"
                                          :class="
                                            $vuetify.breakpoint.mobile
                                              ? 'align-start'
                                              : 'align-center'
                                          "
                                        >
                                          <v-icon
                                            class="me-2"
                                            small
                                            v-text="'mdi-briefcase-outline'"
                                          ></v-icon>
                                          <template v-if="getSpecializations(recipient).length">
                                            <template v-if="$vuetify.breakpoint.mobile">
                                              <span>{{
                                                getSpecializations(recipient).join(', ')
                                              }}</span>
                                            </template>
                                            <template v-else>
                                              <span>{{ getSpecializations(recipient)[0] }}</span>
                                              <v-tooltip
                                                v-if="getSpecializations(recipient).length > 1"
                                                top
                                                color="secondary"
                                              >
                                                <template #activator="{on, attrs}">
                                                  <v-icon
                                                    small
                                                    color="blue"
                                                    class="ms-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-text="'mdi-dots-horizontal-circle-outline'"
                                                  ></v-icon>
                                                </template>
                                                <div class="d-flex flex-column">
                                                  <span
                                                    v-for="(spec, index) in getSpecializations(
                                                      recipient,
                                                      true
                                                    )"
                                                    :key="index"
                                                    class="app-text-size--extra-small my-1"
                                                    >{{ spec }}</span
                                                  >
                                                </div>
                                              </v-tooltip>
                                            </template>
                                          </template>
                                          <span v-else class="grey--text">{{
                                            $t('stubs.notSpecified')
                                          }}</span>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-list-item>
                                  <v-divider
                                    v-if="i + 1 < team.employees.length"
                                    :key="`divider-${recipient.user_id}`"
                                    class="my-3"
                                  ></v-divider>
                                </template>
                              </v-list>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>

                        <v-pagination
                          v-show="timetableCount > timetableRecipients.length"
                          :value="pagination.page"
                          color="blue darken-5"
                          :total-visible="7"
                          :length="Math.ceil(pagination.total / pagination.limit)"
                          @input="onPaginate"
                        ></v-pagination>
                      </div>
                      <p v-else class="grey--text">{{ $t('stubs.nothingFound') }}</p>
                    </template>
                    <p v-else class="grey--text">
                      {{ isTest ? $t('noRecipientsTest') : $t('noRecipients') }}
                    </p>
                  </div>
                </transition>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container fluid pa-0>
            <v-row>
              <v-col cols="6" md="3">
                <v-btn
                  v-if="activeTab === 0"
                  type="submit"
                  block
                  :disabled="!isFormValid || isSubmitting || isLoading || isCompanyLoading"
                  depressed
                  color="primary"
                  class="primary-contrast--text"
                  @click.prevent="onSubmit(false)"
                  >{{ $t('buttons.done') }}</v-btn
                >
                <v-btn
                  v-else
                  type="submit"
                  block
                  :disabled="
                    !deletedRecipients.length || isSubmitting || isLoading || isCompanyLoading
                  "
                  depressed
                  color="primary"
                  class="primary-contrast--text"
                  @click="toggleVisibleDialog({name: 'confirmDeleteRecipients', state: true})"
                  >{{ $t('buttons.done') }}</v-btn
                >
              </v-col>
              <v-col cols="6" md="3">
                <v-btn
                  block
                  outlined
                  :disabled="isSubmitting || isLoading || isCompanyLoading"
                  @click.prevent="isVisibleState = false"
                  >{{ $t('buttons.cancel') }}</v-btn
                >
              </v-col>
              <v-col cols="6" md="3">
                <v-btn
                  v-if="activeTab === 0"
                  type="submit"
                  block
                  :disabled="
                    !localEmployees.length || isSubmitting || isLoading || isCompanyLoading
                  "
                  depressed
                  color="primary"
                  class="primary-contrast--text"
                  @click.prevent="
                    toggleVisibleDialog({name: 'confirmAddAllRecipients', state: true})
                  "
                  >{{ $t('allCompany') }}</v-btn
                >
                <v-btn
                  v-else
                  type="submit"
                  block
                  :disabled="
                    !timetableRecipients.length || isSubmitting || isLoading || isCompanyLoading
                  "
                  depressed
                  color="primary"
                  class="primary-contrast--text"
                  @click="toggleVisibleDialog({name: 'confirmDeleteAllRecipients', state: true})"
                  >{{ $t('fromAllCompany') }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-confirm-dialog
      name="confirmDeleteRecipients"
      :is-visible="isDialogVisible.confirmDeleteRecipients"
      :title="$t('confirmDeleteRecipients.title')"
      :button="$t('buttons.confirmDelete')"
      :is-submitting="isSubmitting"
      @submit="onSubmit(false)"
      @toggle-visible="toggleVisibleDialog"
    >
      <p>
        {{ isTest ? $t('confirmDeleteRecipients.textTest') : $t('confirmDeleteRecipients.text') }}
      </p>
    </app-confirm-dialog>
    <app-confirm-dialog
      name="confirmDeleteAllRecipients"
      :is-visible="isDialogVisible.confirmDeleteAllRecipients"
      :title="$t('confirmDeleteAllRecipients.title')"
      :button="$t('buttons.confirmDelete')"
      :is-submitting="isSubmitting"
      @submit="onSubmit(true)"
      @toggle-visible="toggleVisibleDialog"
    >
      <p>
        {{
          isTest ? $t('confirmDeleteAllRecipients.textTest') : $t('confirmDeleteAllRecipients.text')
        }}
      </p>
    </app-confirm-dialog>
    <app-confirm-dialog
      name="confirmAddAllRecipients"
      :is-visible="isDialogVisible.confirmAddAllRecipients"
      :title="$t('confirmAddAllRecipients.title')"
      :button="$t('buttons.confirmAdd')"
      :is-submitting="isSubmitting"
      @submit="onSubmit(true)"
      @toggle-visible="toggleVisibleDialog"
    >
      <p>
        {{ isTest ? $t('confirmAddAllRecipients.textTest') : $t('confirmAddAllRecipients.text') }}
      </p>
    </app-confirm-dialog>
  </div>
</template>

<script>
import {dialogsMixin} from '@/mixins/dialogs';
import {accessRightsMixin} from '@/mixins/accessRights';

import {
  errorAlert,
  successAlert,
  getFullName,
  getArrayByKey,
  declOfNum,
  range,
  uniqueArray,
} from '@/helpers/utils';
import {required} from '@/helpers/validators';
import _ from 'lodash';
import {PAGINATION_SURVEYS_LIMIT} from '@/components/pages/company/surveys/dialogs/SendSurveyDialogContainer';

export default {
  name: 'AppSendSurveyDialog',
  mixins: [dialogsMixin, accessRightsMixin],
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCompanyLoading: {
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [Number, String],
      default: null,
    },
    validationErrors: {
      type: [Number, String],
      default: null,
    },
    survey: {
      required: false,
      type: Object,
      default() {
        return {};
      },
    },
    recipients: {
      type: Array,
      default() {
        return [];
      },
    },
    timetable: {
      type: Object,
      default() {
        return {};
      },
    },
    managedTeams: {
      type: Array,
      default() {
        return [];
      },
    },
    companySpecializations: {
      type: Array,
      default() {
        return [];
      },
    },
    employees: {
      type: Array,
      default() {
        return [];
      },
    },
    timetableRecipients: {
      type: Array,
      default() {
        return [];
      },
    },
    timetableTeams: {
      type: Array,
      default() {
        return [];
      },
    },
    employeesCount: {
      type: Number,
      default: 0,
    },
    timetableCount: {
      type: Number,
      default: 0,
    },
    employeeFilters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      declOfNum,
      getFullName,
      rules: {required},
      isUpdated: false,
      form: {
        employees: [],
      },
      accordion: [],
      activeTab: 0,
      tabs: [
        {
          title: this.$t('tabs.assign'),
          key: 'assign',
        },
        {
          title: this.$t('tabs.recipients'),
          key: 'recipients',
        },
      ],
      isDialogVisible: {
        confirmDeleteRecipients: false,
        confirmDeleteAllRecipients: false,
        confirmAddAllRecipients: false,
      },
      selectedRecipients: [],
      deletedRecipients: [],
      locationErrors: null,
      offset: 0,
    };
  },
  computed: {
    filteredManagedTeams() {
      const {managedTeams, timetableTeams} = this;
      return (managedTeams || []).filter((t) => (timetableTeams || []).includes(t.team_id));
    },
    applyCallback() {
      return this.activeTab === 0 ? this.onApplyFilters : this.getTimetable;
    },
    teamIds: {
      get() {
        return this.employeeFilters.teamIds && this.employeeFilters.teamIds.length
          ? this.employeeFilters.teamIds[0]
          : null;
      },
      set(value) {
        this.$emit('set-employee-filters', {
          key: 'teamIds',
          value: [value],
          callback: this.applyCallback,
        });
      },
    },
    employeeName: {
      get() {
        return this.employeeFilters.employeeName;
      },
      set(value) {
        this.$emit('set-employee-filters', {
          key: 'employeeName',
          value,
          callback: value && value.length > 2 ? this.applyCallback : undefined,
        });
      },
    },
    specializationIds: {
      get() {
        return this.employeeFilters.specializationIds;
      },
      set(value) {
        this.$emit('set-employee-filters', {
          key: 'specializationIds',
          value,
          callback: this.applyCallback,
        });
      },
    },
    pagination() {
      const {employeesCount, timetableCount, activeTab, offset} = this;

      return {
        total: activeTab === 0 ? employeesCount : timetableCount,
        offset,
        limit: PAGINATION_SURVEYS_LIMIT,
        page: offset / PAGINATION_SURVEYS_LIMIT + 1,
      };
    },
    localEmployees() {
      const {employees} = this;

      if (!employees || !employees.length) return [];

      const idx = employees.findIndex((e) => e.id === -1);
      if (idx >= 0) {
        employees[idx].name = this.$t('withoutTeam');
      }
      return employees;
    },
    isVisibleState: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.toggleVisible(value);
        if (!value) this.resetForm(true);
        this.activeTab = 0;
      },
    },
    isPeriodic() {
      return Boolean(this.survey.period);
    },
    isTest() {
      return Boolean(this.survey.survey_type === 'test');
    },
    userIdField() {
      return 'user_id'; // this.isPeriodic ? 'user_id' : 'employee_id';
    },
    selectUsersField() {
      return this.activeTab === 0 ? this.form.employees : this.selectedRecipients;
    },
    localTeams() {
      return this.activeTab === 0 ? this.localEmployees : this.recipientsTeams;
    },
    localTeamsEmployees() {
      return (this.localTeams || []).map((el) => el.employees).flat();
    },
    localTeamsSpecializations() {
      const currentSpecs = uniqueArray(
        this.localTeamsEmployees.map((e) => e.specializations).flat()
      );

      if (!currentSpecs.length) return [];

      return _.cloneDeep(this.companySpecializations || []).filter((r) =>
        currentSpecs.includes(r.code)
      );
    },
    isOpenAccordion() {
      return Boolean(this.accordion.length);
    },
    localRecipients() {
      const {timetableRecipients} = this;
      if (!timetableRecipients || !timetableRecipients.length) return [];
      return timetableRecipients;
    },
    recipientsTeams() {
      const {localRecipients, deletedRecipients} = this;
      if (!localRecipients.length) return [];

      const idx = localRecipients.findIndex((e) => e.id === -1);
      if (idx >= 0) {
        localRecipients[idx].name = this.$t('withoutTeam');
      }
      if (!deletedRecipients.length) return localRecipients;

      return localRecipients.map((t) => {
        return {
          ...t,
          employees: t.employees.map((e) => {
            return {...e, deleted: deletedRecipients.includes(e.user_id)};
          }),
        };
      });
    },
    isFormValid() {
      return Boolean(this.form.employees.length);
    },
    selectAllEmployeesState() {
      return this.form.employees.length === this.employeesCount || !this.employeesCount;
    },
    selectAllRecipientsState() {
      return this.selectedRecipients.length === this.localRecipients.length || !this.timetableCount;
    },
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.isUpdated = false;
        // this.applyCallback();
        this.onApplyFilters();
        this.getTimetable();
        // this.isPeriodic ? this.getTimetable() : this.getRecipients();
      }
    },
    localTeams(value) {
      if (value && value.length) this.toggleOpenAccordion(true);
    },
    employeeFilters() {
      this.offset = 0;
    },
  },
  mounted() {
    this.toggleOpenAccordion(true);
  },
  methods: {
    onPaginate(e) {
      this.accordion = [];
      this.offset = PAGINATION_SURVEYS_LIMIT * (e - 1);
      this.applyCallback();
    },
    clearEmployee() {
      this.employeeId = null;
      this.applyCallback();
    },
    clearTeams() {
      this.teamIds = [];
      this.applyCallback();
    },
    clearSpecs() {
      this.specializationIds = [];
      this.applyCallback();
    },
    onApplyFilters() {
      const {offset} = this;
      this.$emit('get-employees', {
        pagination: {offset, limit: PAGINATION_SURVEYS_LIMIT},
        successCallback: () => {
          this.isUpdated = true;
          this.openAccordion();
        },
      });
    },
    toggleVisible(state) {
      this.$emit('toggle-visible', {name: 'sendSurvey', state});
    },
    toggleOpenAccordion(state) {
      state ? (this.accordion = range(0, this.localTeams.length)) : (this.accordion = []);
    },
    toggleOpenAccordionItem(item) {
      const index = this.accordion.indexOf(item);
      index > -1 ? this.accordion.splice(index, 1) : this.accordion.push(item);
    },
    openAccordion() {
      this.$nextTick(() => this.toggleOpenAccordion(true));
    },
    getTimetable() {
      const {offset} = this;
      this.$emit('get-timetable', {
        survey_id: this.survey.survey_id,
        pagination: {offset, limit: PAGINATION_SURVEYS_LIMIT},
        successCallback: () => {
          this.isUpdated = true;
          this.openAccordion();
        },
        errorCallback: () => {
          if (this.errors === 403) {
            errorAlert(this.$t('errorMessages.getTimetable'));
            this.locationErrors = this.$t('errorMessages.getTimetable');
          }
        },
      });
    },
    getSpecializations(employee, shift) {
      let result = getArrayByKey(
        employee.specializations,
        this.companySpecializations,
        'code',
        'name'
      );
      if (shift) result.shift();
      return result;
    },
    selectAllEmployees() {
      this.form.employees.push(
        ...this.localEmployees
          .map((t) => t.employees)
          .flat()
          .map((e) => e[this.userIdField])
      );
      this.form.employees = [...new Set([...this.form.employees])];
    },
    selectAllRecipients() {
      this.selectedRecipients.push(
        ...this.localRecipients
          .map((t) => t.employees)
          .flat()
          .map((e) => e[this.userIdField])
      );
      this.selectedRecipients = [...new Set([...this.selectedRecipients])];
    },
    deleteRecipients() {
      if (!this.selectedRecipients.length) return false;
      this.selectedRecipients.forEach((recipient) => this.deletedRecipients.push(recipient));
      this.selectedRecipients = [];
      this.openAccordion();
      this.$emit('set-employee-filters', {reset: true});
    },
    selectAllTeam(teamEmployees) {
      if (!teamEmployees || !teamEmployees.length) return false;
      teamEmployees.forEach((employee) => {
        if (!this.selectUsersField.includes(employee[this.userIdField]))
          this.selectUsersField.push(employee[this.userIdField]);
      });
    },
    deselectAllTeam(teamEmployees) {
      if (!teamEmployees || !teamEmployees.length) return false;
      teamEmployees.forEach((employee) => {
        const index = this.selectUsersField.indexOf(employee[this.userIdField]);
        if (index > -1) this.selectUsersField.splice(index, 1);
      });
    },
    checkSelectAllTeam(teamEmployees) {
      if (!teamEmployees || !teamEmployees.length) return false;
      return teamEmployees.every((employee) =>
        this.selectUsersField.includes(employee[this.userIdField])
      );
    },
    onChangeTab() {
      this.resetForm();
      this.openAccordion();
    },
    onSubmit(all = false) {
      let data = {};

      if (all) {
        data.users_data = this.activeTab === 0 ? [-1] : [];
      } else {
        const recipientsIds = this.localRecipients
          .map((t) => t.employees)
          .flat()
          .map((e) => e.user_id)
          .filter((u) => !this.deletedRecipients.includes(u));
        data.users_data = [
          ...new Set(
            this.activeTab === 0 ? this.form.employees.concat(recipientsIds) : recipientsIds
          ),
        ];
      }

      const dialogName = all
        ? this.activeTab === 0
          ? 'confirmAddAllRecipients'
          : 'confirmDeleteAllRecipients'
        : 'confirmDeleteRecipients';

      this.$emit('change-timetable', {
        eventData: {
          survey_id: this.survey.survey_id,
          data,
        },
        successCallback: () => {
          this.toggleVisibleDialog({
            name: dialogName,
            state: false,
          });
          this.successCallback(
            this.isTest
              ? this.$t('successMessages.changeTimetableTest')
              : this.$t('successMessages.changeTimetable')
          );
        },
        errorCallback: () => {
          this.toggleVisibleDialog({
            name: dialogName,
            state: false,
          });
          this.errorCallback(
            this.isTest
              ? this.$t('errorMessages.changeTimetableTest')
              : this.$t('errorMessages.changeTimetable')
          );
        },
      });
    },
    successCallback(message) {
      this.toggleVisible(false);
      this.resetForm();
      successAlert(message);
    },
    errorCallback(message) {
      errorAlert(`${message}. ${this.validationErrors}`);
    },
    resetForm(isClose) {
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.form.employees = [];
      this.selectedRecipients = [];
      this.deletedRecipients = [];

      this.$emit('set-employee-filters', {
        reset: true,
        callback: isClose
          ? undefined
          : () => {
              if (this.activeTab === 0) {
                this.onApplyFilters();
              }
              this.getTimetable();
            },
      });
      this.offset = 0;
      this.locationErrors = null;
    },
  },
};
</script>

<style scoped lang="scss">
.send-survey-dialog {
  &__row-header {
    max-width: 70%;
  }
}
</style>
